import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { grey, green, red } from '@material-ui/core/colors';
import { useMediaQuery, ButtonBase } from '@material-ui/core';
import echarts from 'echarts'

var axislineColor = new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
    offset: 0,
    color: red[400]
},
{
    offset: 1,
    color: green[400]
}
]);


function Gague(props) {

    const { name, data, onClick, small, big } = props

    const value = data;
    const matches = (useMediaQuery('(max-width:500px)') || small) && !big

    const option = {
        tooltip: {
            formatter: "{a} <br/>{b} : {c}%"
        },
        toolbox: {
            feature: {
                restore: {},
                saveAsImage: {}
            },
            show: false
        },
        series: [{
            min: 0,
            max: 100,
            startAngle: 180,
            endAngle: 0,
            name: name,
            type: 'gauge',
            axisLabel: {
                show: false
            },
            detail: {
                formatter: name,
                color: grey[600],
                fontSize: 14,
                offsetCenter: [0, '16px']
            },
            axisLine: {
                lineStyle: {
                    width: matches ? 5 : 10,
                    color: 
                    [
                        [1, axislineColor]
                        // [value/100, axislineColor],
                        // [1, grey[400]]
                    ]
                }
            },
            pointer: {
                show: true,
                length: '50%',
                width: 3,
            },
            itemStyle: {
                color: '#888'
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            data: [{
                value: value
            }]
        }]
    }    

    return(
        <ButtonBase onClick={onClick}>
            <ReactEcharts option={option} style={{ height: matches ? 80 : 160, width: matches ? 80 : 160 }} />
        </ButtonBase >
    )
}


export default Gague