import React, { useEffect } from 'react'
import { ListItem, ListItemAvatar, Avatar, ListItemText,styled } from '@material-ui/core';

import BusinessIcon from '@material-ui/icons/Business'
import { getCompanyProfile } from '../../store/actions/iexActions';
import { connect } from 'react-redux';
import Axios from 'axios';
const StyledListItem = styled(ListItem)({
    "&:hover": {
      backgroundColor: "#7cc141"
    }
  });

function PreviewStockItem(props) {

    const { equity, profiles, getCompanyProfile, itemClicked,classes} = props
    const image = profiles[equity.symbol] ? profiles[equity.symbol].image: false

    useEffect(()=> {
        //const abortController = new AbortController()
        const signal = Axios.CancelToken.source()

        if (!profiles[equity.symbol]) {
            getCompanyProfile(equity.symbol, signal)
        }

        return () => {
            signal.cancel()
        }
    }, [equity])
    return (
        <StyledListItem
            button
            onClick={itemClicked(equity.symbol)}
            dense
        >
            <ListItemAvatar>
                { image ?
                    <Avatar src={image} alt=''/>
                    :
                    <Avatar>
                        <BusinessIcon />
                    </Avatar>
                }
                
            </ListItemAvatar>
            <ListItemText primary={equity.symbol} secondary={equity.name} />
        </StyledListItem>
    )
}


const mapStateToProps = (state) => {
    return {
        profiles: state.iex.profiles,
        fetchingProfile: state.iex.fetchingProfile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyProfile: (symbol, signal) => dispatch(getCompanyProfile(symbol, signal))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewStockItem)