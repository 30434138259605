import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, compose, applyMiddleware } from "redux";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { reduxFirestore, getFirestore } from "redux-firestore";
import thunk from 'redux-thunk';
import fbConfig from "./config/fbConfig";

import { Provider } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import ReactGA from 'react-ga'
import rootReducer from "./store/reducers/rootReducer";
import { PRIMARY, PRIMARY_TRANSPARENT, SECONDARY } from "./config/config";

ReactGA.initialize('UA-140771558-1')
ReactGA.pageview('/strategy_master')



const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: PRIMARY
    },
    secondary: {
      main: SECONDARY
    }

  }
});

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reactReduxFirebase(fbConfig, {
      userProfile: "users",
      useFirestoreForProfile: true,
      attachAuthIsReady: true
    }),
    reduxFirestore(fbConfig)
  )
);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
