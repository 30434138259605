import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import Recaptcha from 'react-recaptcha'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function ReChaptchaDialog(props) {

    const matches = useMediaQuery('(max-width:500px)')

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='form-dialog-title'
            fullScreen={matches}
        >
            <DialogTitle id="form-dialog-title">Verify that you are human</DialogTitle>
            <DialogContent>
                <Recaptcha
                    sitekey="6LccuqMUAAAAAN7z2pstceF6k8wdm-JMmXQl7VwH"
                    render="explicit"
                    verifyCallback={props.verifyCallback}
                    style={{ maxWidth: '90%'}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">Cancle</Button>
            </DialogActions>

        </Dialog>
    )
}


export default ReChaptchaDialog