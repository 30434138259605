import { enqueueSnackbar } from "./notificationActions";

import ReactGA from 'react-ga'
import { GA_IDENTIFIER } from "../../config/config";



export const WILL_GET_ALL_APPS = 'WILL_GET_ALL_APPS'
export const GET_ALL_APPS_SUCCESS = 'GET_ALL_APPS_SUCCESS'
export const GET_ALL_APPS_FAILURE = 'GET_ALL_APPS_FAILURE'

export const WILL_FILTER_APP = 'WILL_FILTER_APP'
export const FILTER_APPS = 'FILTER_APPS'

export const WILL_GET_SIMILAR_APPS = 'WILL_GET_SIMILAR_APPS'
export const GET_SIMILAR_APPS_SUCCESS = 'GET_SIMILAR_APPS_SUCCESS'
export const GET_SIMILAR_APPS_FAILURE = 'GET_SIMILAR_APPS_FAILURE'



ReactGA.initialize('UA-140771558-1')

export const addAppToMyApps = (app) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        const uid = firebase.auth().currentUser.uid

        firestore.set({ collection: 'userApps', doc: `${uid}_${app.id}`},{ userId: uid, appId: app.id, app: app })
            .then(() => {
                ReactGA.event({
                    category: 'apps',
                    action: `add_app_to_my_apps: ${app.id}`,
                    label: GA_IDENTIFIER,
                })
                dispatch(enqueueSnackbar({
                    message: 'The App was sucessfully added to My Apps',
                    options: {
                        variant: 'success'
                    }
                }))
            })
            .catch((error) => {
                dispatch(enqueueSnackbar({
                    message: error.message,
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}

export const removeAppFromMyApps = (appId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        const uid = firebase.auth().currentUser.uid

        firestore.delete({ collection: 'userApps', doc: `${uid}_${appId}`})
            .then(() => {
                ReactGA.event({
                    category: 'apps',
                    action: `remove_app_from_my_apps: ${appId}`,
                    label: GA_IDENTIFIER,
                })
                dispatch(enqueueSnackbar({
                    message: 'The App was sucessfully removed from My Apps',
                    options: {
                        variant: 'success'
                    }
                }))
            })
            .catch((error) => {
                dispatch(enqueueSnackbar({
                    message: error.message,
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}


export const getAllApps = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore()

        dispatch({
            type: WILL_GET_ALL_APPS,
        })

        firestore.get({ collection: 'apps'})
            .then(results => {
                dispatch({
                    type: GET_ALL_APPS_SUCCESS,
                    data: results.docs.map(doc => doc.data())
                })
            }).catch(error => {
                dispatch({
                    type: GET_ALL_APPS_FAILURE,
                })
            })


    }
}

export const getSimilarApps = (key, value) => {
    return (dispatch, getState, { getFirestore}) => {
        const firestore = getFirestore()

        dispatch({
            type: WILL_GET_SIMILAR_APPS
        })

        firestore.get({ collection: 'apps', where: [key, '==', value]})
        .then(results => {
            dispatch({
                type: GET_SIMILAR_APPS_SUCCESS,
                data: results.docs.map(doc => doc.data())
            })
        }).catch(error => {
            dispatch({
                type: GET_SIMILAR_APPS_FAILURE
            })
        })
    }
}


export const filterAllApps = (searchTerm) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({
            type: WILL_FILTER_APP
        })

        dispatch({
            type: FILTER_APPS,
            data: searchTerm
        })
    }
}