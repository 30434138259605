import React, { useState } from 'react'
import { Grid, Typography, TextField, Button, FormControlLabel, Checkbox, Link } from '@material-ui/core'
import { enqueueSnackbar } from '../../store/actions/notificationActions';
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions';

function SignInDialogContent(props) {
    const { signIn, enqueueSnackbar, switchToIsRegister, handleClose } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [rememberChecked, setRememberChecked] = useState(false)

    const handleSignIn = () => {
        if (email !== '' && password !== '') {
            signIn({
                email: email,
                password: password,
                rememberChecked: rememberChecked
            })
        } else {
            enqueueSnackbar({
                message: 'Please make sure you filled the Email and Password correctly!',
                options: {
                    variant: 'warning'
                }
            })
        }
    }

    const handleRemember = (event) => {
        setRememberChecked(event.target.checked)
    }

    return (
        <Grid container spacing={2} justify='center' style={{ paddingTop: 8, paddingBottom: 8}}>
            <Grid item container xs={12} justify='center' spacing={1}>
                <Grid item>
                    <Typography variant='h5' color='primary'>Sign In</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2' align='center' color='textSecondary'>Please Sign In with your Moving Indicators Account.</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} justify='center' spacing={0}>
                <Grid item >
                    <TextField
                        value={email}
                        type='email'
                        required
                        onChange={(event) => setEmail(event.target.value)}
                        label='Email'
                        margin='dense'
                        variant='outlined'
                        fullWidth
                    />
                    <TextField
                            value={password}
                            type='password'
                            required
                            onChange={(event) => setPassword(event.target.value)}
                            label='Password'
                            margin='dense'
                            variant='outlined'
                            fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={rememberChecked}
                                onChange={handleRemember}  
                                color='primary'
                            />
                        }
                        label={
                            <Typography variant='caption' color='textSecondary'>
                                Remember Me
                            </Typography>
                        }
                    />
                    <br />
                </Grid>
                <Grid item>
                    <Link onClick={switchToIsRegister}>
                        <Typography variant='caption'>
                            Don't have an Acount yet? Sign Up!
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
            <Grid item>
                    <Button variant='outlined' color='primary' onClick={handleClose} disabled={!handleClose}>Cancel</Button>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={handleSignIn}>Sign In</Button>
                </Grid>
        </Grid>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials)),
        enqueueSnackbar: (notification) => dispatch(enqueueSnackbar(notification))
    }
}

export default connect(null, mapDispatchToProps)(SignInDialogContent)