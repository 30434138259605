import { LOGIN_ERROR, LOGIN_SUCCESS, SIGNOUT_SUCCESS, SIGNUP_SUCESS, SIGNUP_ERROR, SUBSCRIBED, UNSUBSCRIBED } from "../actions/authActions";



const initState = {
    authError: null,
    subscriptionStatus: false
}

const authReducer = (state=initState, action) => {
    switch(action.type) {
        case LOGIN_ERROR:
            return { ...state, authError: action.error.message }

        case LOGIN_SUCCESS:
            return { ...state, authError: null}

        case SIGNOUT_SUCCESS:
            return { ...state, authError: null}

        case SIGNUP_SUCESS:
            return { ...state, authError: null}

        case SIGNUP_ERROR:
            return { ...state, authError: action.error.message }

        case SUBSCRIBED:
            return { ...state, subscriptionStatus: true}

        case UNSUBSCRIBED:
            return { ...state, subscriptionStatus: false}



        default:
            return state
    }
}

export default  authReducer