import React, { useEffect } from 'react'
import { List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar } from '@material-ui/core'
import PreviewStockItem from './PreviewStockItem'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { filterAllApps, getAllApps } from '../../store/actions/appActions'
import { getSymbols, filterSymbols } from '../../store/actions/iexActions'
import { getSymbol } from '../../store/actions/searchResultActions'
import { calculate } from '../../store/actions/dataActions'

function QuickSearchResults(props) {

    const { filteredSymbols, filteredApps, isMobile, setAppDetailPageOpen, setApp } = props

    useEffect(() => {
        // Check if Data is Loaded and load if needed
        if (props.symbols.length === 0) {
            props.getSymbols()
        }
        if (props.allApps.length === 0 && !props.gettingApps) {
            props.getAllApps()
        }
    })

    // New search term should only update if it change
    useEffect(() => {
        props.filterSymbols(props.searchTerm)
        props.filterAllApps(props.searchTerm)
    }, [props.searchTerm])

    const itemClicked = name => event => {
        const path = props.history.location.pathname.includes('equity') ? name : `equity/${name}`
        props.setSymbol(name);
        props.calculate(name);
        props.history.push(path)
    }

    const appItemClicked = app => event => {
        setApp(app)
        setAppDetailPageOpen(true)
    }

    return (
            <List style={{ width: '100%', height: 'auto', borderRadius: isMobile ? 0 : 28, paddingTop: isMobile ? 56 : 0, overflowX: 'hidden'}}>
                {filteredSymbols.map(symbol => {
                    return (
                        <PreviewStockItem key={symbol.symbol} equity={symbol} itemClicked={itemClicked} />
                    )
                })
                }
                { filteredApps.length > 0 ? <Divider /> : null}
                { filteredApps.map(app => {
                    return (
                        <ListItem 
                            key={app.id} 
                            button
                            onClick={appItemClicked(app)}
                            dense
                        >
                            <ListItemAvatar>
                            <Avatar src={app.logo} alt='' />
                            </ListItemAvatar>
                            <ListItemText
                                primary={app.name} 
                                secondary={app.mainCategory} 
                            />
                        </ListItem>
                    )
                })

                }
                {/* { filteredSymbols.length === 0 ?
                    <ListItem>
                        <ListItemText primary="Sorry we could not find any matches ..." />
                    </ListItem> : null
                } */}
            </List>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        allApps: state.app.allApps,
        symbols: state.iex.symbols,
        filteredSymbols: state.iex.filteredSymbols,
        filteredApps: state.app.filteredApps,
        gettingApps: state.app.gettingApps

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSymbols: () => dispatch(getSymbols()),
        filterSymbols: (searchTerm) => dispatch(filterSymbols(searchTerm)),
        getAllApps: () => dispatch(getAllApps()),
        filterAllApps: (searchTerm) => dispatch(filterAllApps(searchTerm)),
        setSymbol: (searchSymbol) =>dispatch(getSymbol(searchSymbol)),
        calculate:(searchSymbol) => dispatch(calculate(searchSymbol))
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickSearchResults))