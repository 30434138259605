import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, withStyles } from '@material-ui/core'
import SignInDialogContent from './SignInDialogContent';
import SignUpDialogContent from './SignUpDailogContent';
import {connect} from 'react-redux'

const SmallDialog = withStyles(theme => ({
    paperWidthSm: {
        maxWidth: 300
    }
}))(Dialog)



function SignInDialog(props) {
    const { open, onClose, signUp, auth } = props
    const [isSignIn, setIsSignIn] = useState(!signUp)

    useEffect(() => {
        if (auth && auth.uid && open) {
            onClose(true)
        }
    }, [auth])

    useEffect(() => {
        setIsSignIn(!signUp)
    }, [signUp])

    const handleSwitchToRegister = () => {
        setIsSignIn(false)
    }

    const handleSwitchToLogin = () => {
        setIsSignIn(true)
    }

    const Content = isSignIn ? 
            <SignInDialogContent switchToIsRegister={handleSwitchToRegister} handleClose={onClose}/> 
        : 
            <SignUpDialogContent switchToLogin={handleSwitchToLogin} handleClose={onClose}/>

    return (
        <SmallDialog
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                {Content}
            </DialogContent>
        </SmallDialog>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth
    }
}
export default connect(mapStateToProps)(SignInDialog)