import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { grey, green, red } from '@material-ui/core/colors';
import { Button, ButtonGroup, makeStyles, useMediaQuery } from '@material-ui/core';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    button: {
        color: grey[400], 
        borderWidth: 0
    },
    buttonSelected: {
        color: grey[600],
        fontWeight: 'bold',
        borderWidth: 0
    }
}))

const timeframeToDays = (timeframe) => {
    switch (timeframe) {
        case '5D':
            return 5;
        case '1M':
            return 20;
        case '6M':
            return 120;
        case 'YTD':
            return 1000000000000
        case '1Y':
            return 252;
        case '5Y':
            return 252*5;
        case 'Max':
            return 1000000000000;
        default:
            return 5;
    }
}

function PriceChart(props) {

    const matches = useMediaQuery('(max-width:500px)')
    const { symbol, data } = props

    const [timeframe, setTimeframe] = React.useState(matches ? '1M' : '6M');

    const year = moment().format('YYYY');

    var categoryData = [];
    var values = []
    var vols = []


    var indexYTD = -1

    for (var i = 0; i < data.length; i++) {
        categoryData.push(data[i]['date']);
        values.push([ data[i]['open'], data[i]['close'], data[i]['low'], data[i]['high']])
        vols.push(data[i]['unadjustedVolume'])

        if (indexYTD === -1) {
            if (data[i]['date'].includes(year)) {
                indexYTD = i
            }
        }
        
    }

    const ansDays = timeframeToDays(timeframe) > vols.length ? vols.length : timeframeToDays(timeframe)

    const startValue = timeframe === 'YTD' ? indexYTD : categoryData.length - ansDays
    const endValue = categoryData.length

    // var minValue = Math.min( ...values.map(v => v[2] ))
    // console.log(minValue)
    // var minValue = minValue - minValue * 0.03

    const option = {
            title: {
                text: symbol,
                left: 0,
                show: false
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line'
                }
            },
            legend: {
                data: [symbol],
                show: false
            },
            grid: [{
                top: '16px',
                left: '0px',
                right: '48px',
                height: matches ? '200px' : '352px'
            },{
                left: '0px',
                right: '48px',
                top: matches ? '232px': '388px',
                height: matches ? '32px': '10%'
            }],
            xAxis: [{
                type: 'category',
                data: categoryData,
                scale: true,
                boundaryGap : false,
                splitLine: {show: false},
                splitNumber: 20,
                min: 'dataMin',
                max: 'dataMax',
                axisLine: {
                    onZero: false,
                    show: true,
                    lineStyle: {
                        color: grey[600]
                    },
                },
                nameTextStyle: {
                    color: grey[600]
                },
            },
            {
                type: 'category',
                gridIndex: 1,
                data: categoryData,
                axisLabel: {show: false},
                axisTick: {show: false},
                axisLine: {
                    onZero: false,
                    show: true,
                    lineStyle: {
                        color: grey[600]
                    },
                },
            }],
            yAxis: [{
                scale: true,
                position: 'right',
                splitArea: {
                    show: false,
                },
                boundaryGap: ['10%', '10%'],
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: grey[600]
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: grey[600]
                    },
                },
                nameTextStyle: {
                    color: grey[600]
                },
            },
            {
                gridIndex: 1,
                splitNumber: 2,
                axisLine: {
                    show: false,
                    onZero: false,
                    lineStyle: {
                        color: grey[600]
                    },
                },
                axisTick: {show: false},
                splitLine: {show: false},
                axisLabel: {show: false},
            }],
            dataZoom: [
                {
                    type: 'inside',
                    startValue: startValue,
                    endValue: endValue,
                    xAxisIndex: [0, 0],
                },
                {
                    show: matches ? false : true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    y: '90%',
                    startValue: startValue,
                    endValue: endValue
                }
            ],
            series: [
                {
                    name: symbol,
                    type: 'candlestick',
                    data: values,
                    itemStyle: {
                        normal: {
                            color: green[600],
                            color0: red[600],
                            borderColor: green[600],
                            borderColor0: red[600],
                        }
                    },
                    markLine: {
                        symbol: ['none', 'none'],
                        label: {
                            position: 'middle'
                        },
                        
                        data: [
                            {
                                name: 'min line on lowest',
                                type: 'min',
                                valueDim: 'lowest',
                                lineStyle: {
                                    color: red[600],
                                    type: 'solid',
                                    width: 2
                                },
                            },
                            {
                                name: 'max line on highest',
                                type: 'max',
                                valueDim: 'highest',
                                lineStyle: {
                                    color: green[600],
                                    type: 'solid',
                                    width: 2
                                },
                            }
                        ]
                    },
                },
                {
                    name: 'Volumn',
                    type: 'bar',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    data: vols,
                    itemStyle: {
                        normal: {
                            color: function(params) {
                                var colorList;
                                if (values[params.dataIndex][1]>values[params.dataIndex][0]) {
                                    colorList = '#ef232a';
                                } else {
                                    colorList = '#14b143';
                                }
                                return colorList;
                            },
                        }
                    }
                }
            ]
    }


    const handleSelectTimeframe = event => {
        setTimeframe(event.target.textContent)
    }

    const classes = useStyles()

    return (
        <div>
            <ButtonGroup fullWidth aria-label="full width outlined button group">
                <Button className={timeframe === '5D' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>5D</Button>
                <Button className={timeframe === '1M' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>1M</Button>
                <Button className={timeframe === '6M' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>6M</Button>
                <Button className={timeframe === 'YTD' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>YTD</Button>
                <Button className={timeframe === '1Y' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>1Y</Button>
                <Button className={timeframe === '5Y' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>5Y</Button>
                <Button className={timeframe === 'MAX' ? classes.buttonSelected : classes.button} onClick={handleSelectTimeframe}>Max</Button>
            </ButtonGroup>
            <ReactEcharts option={option} style={{ height: matches ? 300 : 500 }} />
        </div>
    )
}


export default PriceChart