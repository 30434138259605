import React, { useState } from 'react'
import { Grid, Typography, TextField, Button, FormControlLabel, Checkbox, Link } from '@material-ui/core'
import { enqueueSnackbar } from '../../store/actions/notificationActions';
import { connect } from 'react-redux'
import ReChaptchaDialog from './ReChaptchaDialog';
import { signUp } from '../../store/actions/authActions';

function SignUpDialogContent(props) {
    const { enqueueSnackbar, signUp, switchToLogin, handleClose } = props

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPAssword] = useState("")

    const [errorFirstName, setErrorFirstName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)

    const [agreeChecked, setAgreeChecked] = useState(false)
    const [ageChecked, setAgeChecked] = useState(false)

    const [reChaptchaDialogOpen, setReChaptchaDialogOpen] = useState(false)

    const openTermsAndConditions = (event) => {
        event.preventDefault()
        window.open('https://movingindicators.com/terms_and_conditions')
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) && email !== ""
    }

    const checkForm = () => {
        var valid = true

        // Check if all values are set
        // could be done better via form
        setErrorFirstName(firstName === "")
        setErrorLastName(lastName === "")
        setErrorEmail(!validateEmail(email))

        if (errorFirstName || errorLastName || errorEmail) {
            enqueueSnackbar({
                message: 'Please make sure that all the required fields are correctly filled!',
                options: {
                    variant: 'error'
                }
            })
            valid = false
        }

        // Check if user is 18 or older
        if (!ageChecked) {
            enqueueSnackbar({
                message: 'Please verify that you are at least 18 before registering!',
                options: {
                    variant: 'error'
                }
            })
            valid = false
        }

        // Check if password has min length
        if (password.length < 8) {
            setErrorPassword(true)
            enqueueSnackbar({
                message: 'The password you choose is to short! The minimum required lenght is eight symbols!',
                options: {
                    variant: 'error'
                }
            })
            valid = false
        } else {
            setErrorPassword(false)
        }

        // Check if password equals confirm password
        if (password !== confirmPassword) {
            setErrorPassword(true)
            enqueueSnackbar({
                message: 'Error confirming password, please try again!',
                options: {
                    variant: 'error'
                }
            })
            valid = false
        }

        // Check if user agrees to terms and conditions
        if (!agreeChecked) {
            enqueueSnackbar({
                message: 'In order to create an account, you have to agree with the terms and conditions!',
                options: {
                    variant: 'error'
                }
            })
            valid = false
        }

        return valid
    }

    const handleReChaptchaDialogClose = () => {
        console.log('close')
        setReChaptchaDialogOpen(false)
    }

    const handleSubmit = () => {
        if (checkForm()) {
            setReChaptchaDialogOpen(true)
        }
    }

    const verifyCallback = (callback) => {
        if (callback) {
            handleReChaptchaDialogClose()
            signUp({
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password
            })
        }
    }



    return (
        <form>
            <Grid container spacing={2} justify='center' style={{ paddingTop: 8, paddingBottom: 8 }}>
                <Grid item container xs={12} justify='center' spacing={1}>
                    <Grid item>
                        <Typography variant='h5' color='primary'>Sign Up</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' align='center' color='textSecondary'>Please complete to create your account.</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} justify='center' spacing={0}>
                    <Grid item >
                        <TextField
                            value={firstName}
                            type='text'
                            required
                            onChange={(event) => setFirstName(event.target.value)}
                            label='First Name'
                            margin='dense'
                            variant='outlined'
                            error={errorFirstName}
                            fullWidth
                        />
                        <TextField
                            value={lastName}
                            type='text'
                            required
                            onChange={(event) => setLastName(event.target.value)}
                            label='Last Name'
                            margin='dense'
                            variant='outlined'
                            error={errorLastName}
                            fullWidth
                        />
                        <TextField
                            value={email}
                            type='email'
                            required
                            onChange={(event) => setEmail(event.target.value)}
                            label='Email'
                            margin='dense'
                            variant='outlined'
                            error={errorEmail}
                            fullWidth
                        />
                        <TextField
                            value={password}
                            type='password'
                            required
                            onChange={(event) => setPassword(event.target.value)}
                            label='Password'
                            margin='dense'
                            variant='outlined'
                            error={errorPassword}
                            fullWidth
                        />
                        <TextField
                            value={confirmPassword}
                            type='password'
                            required
                            onChange={(event) => setConfirmPAssword(event.target.value)}
                            label='Confirm Password'
                            margin='dense'
                            variant='outlined'
                            error={errorPassword}
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ageChecked}
                                    onChange={(event) => setAgeChecked(event.target.checked)}
                                    color='primary'
                                />
                            }
                            label={
                                <Typography variant='caption' color='textSecondary'>
                                    I am 18 years or older
                            </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeChecked}
                                    onChange={(event) => setAgreeChecked(event.target.checked)}
                                    color='primary'
                                />
                            }
                            label={
                                <Typography variant='caption' color='textSecondary'>
                                    I agree with <Link onClick={openTermsAndConditions} >Terms & Condition</Link>
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Link onClick={switchToLogin}>
                            <Typography variant='caption'>
                                Already Registered? Go to Login!
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button variant='outlined' color='primary' onClick={handleClose} disabled={!handleClose}>Cancel</Button>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={handleSubmit}>Sign Up</Button>
                </Grid>
            </Grid>
            <ReChaptchaDialog open={reChaptchaDialogOpen} handleClose={handleReChaptchaDialogClose} verifyCallback={verifyCallback} />
        </form>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        enqueueSnackbar: (notification) => dispatch(enqueueSnackbar(notification)),
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(null, mapDispatchToProps)(SignUpDialogContent)