import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, Button, Typography, Tab, Tabs, Paper } from '@material-ui/core'
import { connect } from 'react-redux'
import CandlePatternChart from './CandlePatternChart'
import SwipeableViews from 'react-swipeable-views';
import { grey, red, green } from '@material-ui/core/colors';

function CandleStickPatternsDialog(props) {

    const [tabIndex, setTabIndex] = useState(0)
    const {open, onClose, isBearish, isBullish, historicalEquityPrice} = props
    function handleClose() {
        onClose()
    }
    const bearishItems = isBearish.map(item => {
        return (
            <Grid item key={item.name}  xs={12} sm={6}>
                <Paper  style={{ padding: 16, backgroundColor: grey[100]}}>
                    <Grid container spacing={0} justify='center'>
                        <Grid item xs={12}>
                        <Typography variant='body1' style={{ color: red[400], width: '100%'}} align='center'>{item.name}</Typography>
                        </Grid>
                        <Grid item>
                            <CandlePatternChart name={item.name} data={historicalEquityPrice} length={item.days}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    })

    const bullishItems = isBullish.map(item => {
        return (
            <Grid item key={item.name}  xs={12} sm={6}>
                <Paper  style={{ padding: 16, backgroundColor: grey[100]}}>
                    <Grid container spacing={0} justify='center'>
                        <Grid item xs={12}>
                        <Typography variant='body1' style={{ color: green[400], width: '100%'}} align='center'>{item.name}</Typography>
                        </Grid>
                        <Grid item>
                            <CandlePatternChart name={item.name} data={historicalEquityPrice} length={item.days}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    })

    function handleChangeIndex(index) {
        setTabIndex(index)
    }

    function handleChange(event, newValue) {
        setTabIndex(newValue)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="candlestick-pattern-dialog-title"
        >
            <DialogTitle id="candlestick-pattern-dialog-title">Candlestick Patterns</DialogTitle>
            <DialogContent>
                {/* <Hidden smDown>
                    <Grid container spacing={2} direction='row'>
                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant='body1' color='primary'>Bullish</Typography>
                            </Grid>
                            {bullishItems}
                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='body1' color='secondary'>Bearish</Typography>
                            </Grid>
                            {bearishItems}
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp> */}
                    <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary" textColor='primary' variant="fullWidth">
                        <Tab label={`Bullish (${bullishItems.length})`} />
                        <Tab label={`Bearish (${bearishItems.length})`}/>
                    </Tabs>
                    <SwipeableViews
                        index={tabIndex}
                        onChangeIndex={handleChangeIndex}
                        style={{ overflowX: 'hidden'}}
                    >
                        <div style={{ overflowX: 'hidden', padding: 16}}>
                            <Grid container spacing={2} justify='center'>
                                {bullishItems}
                            </Grid>
                        </div>
                        <div style={{ overflowX: 'hidden', padding: 16}}>
                            <Grid container spacing={2} justify='center'>
                                {bearishItems}
                            </Grid>
                        </div>
                    </SwipeableViews>
                {/* </Hidden> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>Done</Button>
            </DialogActions>

            
        </Dialog>
    )
}


const mapStateToProps = state => {
    return {
        isBearish: state.iex.isBearish,
        isBullish: state.iex.isBullish,
        historicalEquityPrice: state.iex.historicalEquityPrice
    }
}

export default connect(mapStateToProps)(CandleStickPatternsDialog)