import { GET_THE_CURRENT_SYMBOL } from "../actions/searchResultActions"


const initState = {
    symbol:"AAPL"
}

const searchResultReducer = (state=initState, action) => {
    switch (action.type) {
        case GET_THE_CURRENT_SYMBOL:
            return {
                ...state,
                symbol: action.data
            }

        default:
            return state
    }
}

export default searchResultReducer