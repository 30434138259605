import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const styles = theme => ({
  mainContainer: {
    padding: 32, 
    height: '80vh'
  },
  widgetContainer: {
    height: '100%',
    width: '100%'
  },
  widgetContainerSmall: {
    height: "100%",
    width: "100%",
    overflowX: "auto"
  }
});

class TechnicalAnalysisWidget extends Component {
  state = {};

  componentDidMount () {
    const script = document.createElement("script");

    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";

    script.type= 'text/javascript';

    script.async = true;

    document.body.appendChild(script);
    document.body.removeChild(script);
}
componentDidUpdate(){
    const script = document.createElement("script");

    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";

    script.type= 'text/javascript';

    script.async = true;

    document.body.appendChild(script);
    document.body.removeChild(script);
}

  render() {
    const { classes,symbol } = this.props;
    return (
      <div className={classes.mainContainer}>
        <Hidden smDown>
            <div  dangerouslySetInnerHTML={{__html: '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/'+symbol+'/technicals/" rel="noopener" target="_blank"><span class="blue-text">Technical Analysis for '+symbol+'</span></a> by TradingView</div><script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js" async>{"interval": "1m","width": 405,"isTransparent": false,"height": 450,"symbol":"'+symbol+'","showIntervalTabs": true,"locale": "en","colorTheme": "light"}</script></div>'}} className={classes.widgetContainer} />
            </Hidden>
            <Hidden mdUp>
            <div  dangerouslySetInnerHTML={{__html: '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/'+symbol+'/technicals/" rel="noopener" target="_blank"><span class="blue-text">Technical Analysis for '+symbol+'</span></a> by TradingView</div><script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js" async>{"interval": "1m","width": "100%","isTransparent": false,"height": "90%","symbol":"'+symbol+'","showIntervalTabs": true,"locale": "en","colorTheme": "light"}</script></div>'}} className={classes.widgetContainer} />
            </Hidden>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(TechnicalAnalysisWidget)));
