/* eslint-disable no-template-curly-in-string */
import React, { PureComponent } from "react";
import ReactEcharts from "echarts-for-react";
import { withStyles, rgbToHex } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PRIMARY } from "../../config/config";
import { SECONDARY } from "../../config/config";
// Arithmetic mean
let getMean = function (data) {
  return data.reduce(function (a, b) {
      return Number(a) + Number(b);
  }) / data.length;
};

// Standard deviation
export const getSD = (data) =>{
  let m = getMean(data);
  return Math.sqrt(data.reduce(function (sq, n) {
          return sq + Math.pow(n - m, 2);
      }, 0) / (data.length - 1));
};
const styles = theme => ({
  chart: {
    height: "400px",
    width: "100%",
    margin: "auto"
  },
  paperContainer: {
    height: 608,
    padding: 16
  },
});

export const LONG_CALL = "Long Call"

function dataGraphCalculation(selection, profile) {
  var data = [[0,0],[0,0],[0,0]];
  var smooth = false;
  var middleLine = [];
  var firstStandardDeviation = [];
  var secondStandardDeviation = [];
  if(profile!= undefined){
  var price  = profile.price;
  }
  
  switch (selection) {
    case LONG_CALL:
    case "Protective Put":
      data = [[0, -150], [20, -150], [40, 300]];
      middleLine = [[20, price],[20, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40])/2, -150],[middleLine[0][0]-getSD([0,20, 40])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40])/2, -150],[middleLine[0][0]+getSD([0,20, 40])/2, 150]]
      break;
    case "Short Call":
    case "Covered Put":
      data = [[0, 150], [20, 150], [40, -300]];
      middleLine = [[20, price],[20, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40])/2, -150],[middleLine[0][0]-getSD([0,20, 40])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40])/2, -150],[middleLine[0][0]+getSD([0,20, 40])/2, 150]]
      break;
    case "Long Put":
    case "Protective Call":
      data = [[0, 300], [15, -150], [30, -150]];
      middleLine = [[15, price],[15, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30])/2, -150],[middleLine[0][0]-getSD([0,15, 30])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30])/2, -150],[middleLine[0][0]+getSD([0,15, 30])/2, 150]]
      break;
    case "Short Put":
    case "Covered Call":
      data = [[0, -300], [15, 150], [30, 150]];
      middleLine = [[15, price],[15, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30])/2, -150],[middleLine[0][0]-getSD([0,15, 30])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30])/2, -150],[middleLine[0][0]+getSD([0,15, 30])/2, 150]]
      break;
    case "Bull Call Spread":
      data = [[0, -150], [20, -150], [40, 150], [60, 150]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;
    case "Bear Put Spread":
      data = [[0, 150], [20, 150], [40, -150], [60, -150]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;
    case "Long Butterfly":
      data = [[0, -150], [15, -150], [30, 150], [45, -150], [60, -150]];
      middleLine = [[30, price],[30, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30,45,60])/2, -150],[middleLine[0][0]-getSD([0,15, 30,45,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30,45,60])/2, -150],[middleLine[0][0]+getSD([0,15, 30,45,60])/2, 150]]
      break;
    case "Short Butterfly":
      data = [[0, 150], [15, 150], [30, -150], [45, 150], [60, 150]];
      middleLine = [[30, price],[30, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30,45,60])/2, -150],[middleLine[0][0]-getSD([0,15, 30,45,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30,45,60])/2, -150],[middleLine[0][0]+getSD([0,15, 30,45,60])/2, 150]]
      break;
    case "Long Condor":
      data = [
        [0, -100],
        [20, -100],
        [40, 100],
        [60, 100],
        [80, 100],
        [100, -100],
        [120, -100]
      ];
      middleLine = [[60, price],[60, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60,80,100,120])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60,80,100,120])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60,80,100,120])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60,80,100,120])/2, 150]]
      break;
    case "Short Condor":
      data = [
        [0, 100],
        [20, 100],
        [40, -100],
        [60, -100],
        [80, -100],
        [100, 100],
        [120, 100]
      ];
      middleLine = [[60, price],[60, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60,80,100,120])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60,80,100,120])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60,80,100,120])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60,80,100,120])/2, 150]]
      //done
      break;
    case "Long Straddle":
      data = [[0, 200], [15, -150], [30, 200]];
      middleLine = [[15, price],[15, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30])/2, -150],[middleLine[0][0]-getSD([0,15, 30])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30])/2, -150],[middleLine[0][0]+getSD([0,15, 30])/2, 150]]
      break;
    case "Short Straddle":
      data = [[0, -150], [15, 200], [30, -150]];
      middleLine = [[15, price],[15, -1*price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30])/2, -150],[middleLine[0][0]-getSD([0,15, 30])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30])/2, -150],[middleLine[0][0]+getSD([0,15, 30])/2, 150]]
      break;
    case "Long Strangle":
      data = [[0, 100], [20, -100], [40, -100], [60, 100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;
    case "Short Strangle":
      data = [[0, -100], [20, 100], [40, 100], [60, -100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;

    case "Ratio Call Spread":
      data = [[0, -100], [20, -100], [40, 100], [60, -100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;
    case "Ratio Put Spread":
      data = [[0, -100], [20, 100], [40, -100], [60, -100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;
    case "Collar":
      data = [[0, -100], [20, -100], [40, 100], [60, 100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20, 40,60])/2, -150],[middleLine[0][0]-getSD([0,20, 40,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20, 40,60])/2, -150],[middleLine[0][0]+getSD([0,20, 40,60])/2, 150]]
      break;
    case "Reversal":
      data = [[0, 100], [20, 100]];
      middleLine = [[10, -1*price],[10, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20])/2, -150],[middleLine[0][0]-getSD([0,20])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20])/2, -150],[middleLine[0][0]+getSD([0,20])/2, 150]]
      break;
    case "Conversion":
      data = [[0, 100], [20, 100]];
      middleLine = [[10, -1*price],[10, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,20])/2, -150],[middleLine[0][0]-getSD([0,20])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,20])/2, -150],[middleLine[0][0]+getSD([0,20])/2, 150]]
      break;
    case "Long Calendar Spread":
      data = [[0, -100], [15, 0], [30, 200], [30, 200], [45, 0], [60, -100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30,30,45,60])/2, -150],[middleLine[0][0]-getSD([0,15, 30,30,45,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30,30,45,60])/2, -150],[middleLine[0][0]+getSD([0,15, 30,30,45,60])/2, 150]]
      smooth = true;
      break;
    case "Short Calendar Spread":
      data = [[0, 100], [15, 0], [30, -200], [30, -200], [45, 0], [60, 100]];
      middleLine = [[30, -1*price],[30, price]];
      firstStandardDeviation = [[middleLine[0][0]-getSD([0,15, 30,30,45,60])/2, -150],[middleLine[0][0]-getSD([0,15, 30,30,45,60])/2, 150]]
      secondStandardDeviation = [[middleLine[0][0]+getSD([0,15, 30,30,45,60])/2, -150],[middleLine[0][0]+getSD([0,15, 30,30,45,60])/2, 150]]
      smooth = true;
      break;
    default:
      data = [];
      smooth = false;
      break;
  }
  return {
    data: data,
    smooth: smooth,
    middleLine: middleLine,
    firstStandardDeviation:firstStandardDeviation,
    secondStandardDeviation:secondStandardDeviation
    
  }
};

class ProfitAndLossDiagram extends PureComponent {
  getOption = () => {
    return {
      title: {
        text: this.props.optionStrategy.toUpperCase(),
        x: "center"
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          params = params[1];
          if(params!=null){
          return params.seriesName+":"+" "+params.data[1];
          }
          else{
            return " ";
          }
      },
    },
      grid: {
        left: "8%",
        right: "8%",
        bottom: "25%",
        containLabel: true
      },
      xAxis: [
        {
          name: "Stock Price at Expiration",
          type: "value",
          nameLocation: "middle",
          nameGap: 7,
          boundaryGap: false,
          axisLabel: { show: false },
          splitLine: { show: false }
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Profit or Loss",
          nameLocation: "middle",
          nameGap: 7,
          axisLine: {
            show: true
          },
          axisLabel: { show: false },
          splitLine: { show: false },
          min: -400,
          max: 400,
          interval: 400
        }
      ],
      series: [
        {
          name: "Profit or Loss",
          type: "line",
          data: dataGraphCalculation(this.props.optionStrategy,this.props.profiles[this.props.symbol]).data,
          lineStyle: { color: PRIMARY },
          itemStyle: {
            normal: {
              borderColor: "black"
            }
          },
          smooth: dataGraphCalculation(this.props.optionStrategy,this.props.profiles[this.props.symbol]).smooth
        },
        {
          name: "Price",
          type: "line",
          data: dataGraphCalculation(this.props.optionStrategy,this.props.profiles[this.props.symbol]).middleLine,
          lineStyle: { color: "red" },
          itemStyle: {
            normal: {
              borderColor: "black"
            }
          },
          smooth: dataGraphCalculation(this.props.optionStrategy,this.props.profiles[this.props.symbol]).smooth
        },
        {
          name: "First Standard Deviation",
          type: "line",
          data: dataGraphCalculation(this.props.optionStrategy,this.props.profiles[this.props.symbol]).firstStandardDeviation,
          lineStyle: { color: SECONDARY  },
          itemStyle: {
            normal: {
              borderColor: "black"
            }
          }
        },
        {
          name: "Second Standard Deviation",
          type: "line",
          data: dataGraphCalculation(this.props.optionStrategy,this.props.profiles[this.props.symbol]).secondStandardDeviation,
          lineStyle: { color: SECONDARY },
          itemStyle: {
            normal: {
              borderColor: "black"
            }
          }
        }
      ]
    };
  };

  render() {
    // console.log(dataGraphCalculation(this.props.strategies.optionStrategy).data);
    const { classes } = this.props;
    return (
      <Paper elevation={5} className={classes.paperContainer} style={{borderRadius: 30}}>
        <ReactEcharts
          option={this.getOption()}
          className={classes.chart}
          style={{ height: "500px" }}
        />
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    profiles: state.iex.profiles,
    symbol: state.search.symbol
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ProfitAndLossDiagram)));
