const optionStrategiesList = [
  "Long Call",
  "Short Call",
  "Long Put",
  "Short Put",
  "Covered Call",
  "Covered Put",
  "Protective Call",
  "Protective Put",
  "Bull Call Spread",
  "Bear Put Spread",
  "Long Butterfly",
  "Short Butterfly",
  "Long Condor",
  "Short Condor",
  "Long Straddle",
  "Short Straddle",
  "Long Strangle",
  "Short Strangle",
  "Ratio Call Spread",
  "Ratio Put Spread",
  "Collar",
  "Reversal",
  "Conversion",
  "Long Calendar Spread",
  "Short Calendar Spread"
];

export const strategySelectionMethod = (currentState, name, event) => {
  var resultOptionStartegies = parseInt("1111111111111111111111111", 2);
  var optionStrategyResultArray = [];
  switch (name) {
    case "marketOutlook":
      switch (event.target.value) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.VolatilityView) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.TimeErosion) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Dividends) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Interest) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Profit) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Loss) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    case "volatilityView":
      switch (currentState.MarketOutlook) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (event.target.value) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.TimeErosion) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Dividends) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Interest) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Profit) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Loss) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    case "timeErosion":
      switch (currentState.MarketOutlook) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.VolatilityView) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (event.target.value) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Dividends) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Interest) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Profit) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Loss) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    case "dividends":
      switch (currentState.MarketOutlook) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.VolatilityView) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.TimeErosion) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (event.target.value) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Interest) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Profit) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Loss) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    case "interest":
      switch (currentState.MarketOutlook) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.VolatilityView) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.TimeErosion) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Dividends) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (event.target.value) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Profit) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Loss) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    case "profit":
      switch (currentState.MarketOutlook) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.VolatilityView) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.TimeErosion) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Dividends) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Interest) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (event.target.value) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Loss) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    case "loss":
      switch (currentState.MarketOutlook) {
        case "Bull":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001100010000000001000000", 2);
          break;
        case "Bear":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110010001000000000100000", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000001100111111110011111", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.VolatilityView) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000001111100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.TimeErosion) {
        case "Helps":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0101110000101001011100010", 2);
          break;
        case "Hurts":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010001100010110100000001", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011000000000011100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Dividends) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Interest) {
        case "Increase":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1001011000000000000101011", 2);
          break;
        case "Decrease":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0110100100000000001010101", 2);
          break;
        case "Neutral":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0000000011111111110000000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (currentState.Profit) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0111111011111110101111011", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1000000100000001010000100", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      switch (event.target.value) {
        case "Limited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1010101111111110100011110", 2);
          break;
        case "Unlimited":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0100010000000001011000001", 2);
          break;
        case "Substantial":
          resultOptionStartegies =
            resultOptionStartegies & parseInt("0001000000000001010100000", 2);
          break;
        default:
          resultOptionStartegies =
            resultOptionStartegies & parseInt("1111111111111111111111111", 2);
      }
      break;
    default:
      resultOptionStartegies =
        resultOptionStartegies & parseInt("1111111111111111111111111", 2);
  }

  while ((resultOptionStartegies.toString(2) + "").length < 25) {
    resultOptionStartegies = "0" + resultOptionStartegies.toString(2);
  }
  for (var j = 0; j < optionStrategiesList.length; j++) {
    if ((resultOptionStartegies.toString(2) + "")[j] === "1") {
      optionStrategyResultArray.push(optionStrategiesList[j]);
    }
  }
  return(optionStrategyResultArray);
};
