import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faIndustry, faFileInvoiceDollar, faChartPie, faHashtag, faUser, faPercentage, faCalendar, faCoins, faHandHoldingUsd, faCalendarDay, faMoneyCheckAlt, faSquare } from '@fortawesome/free-solid-svg-icons'
import { PRIMARY } from '../../config/config'
import { connect } from 'react-redux'

function GeneralInformationElement(props) {
    const { name, value, image } = props
    return (
        <ListItem dense disableGutters>
            <ListItemAvatar>
                <Avatar>
                    <FontAwesomeIcon icon={image} color={PRIMARY} />
                </Avatar>
                {/* <Avatar src={image} style={{ padding: 10}} /> */}
            </ListItemAvatar>
            <ListItemText 
                primary={
                    <Typography variant='caption' style={{ color: grey[600]}}>
                        {name} <br/>
                    </Typography>
                } 
                secondary={
                    <Typography variant='caption' style={{ color: grey[400]}}>
                        {value}
                    </Typography>
                } 
            />
        </ListItem>
    )
}


function GeneralInformation(props) {

    const { profile, metrics, incomeStatement, enterpriseValues } = props

    const dividendYield = metrics && metrics[0] ? Math.round(metrics[0]['Dividend Yield'] * 10000) / 10000 : 0
    const earningsYield = metrics && metrics[0] ? Math.round(metrics[0]['Earnings Yield'] * 10000) / 10000 : 0
    const marketCap = profile && profile.mktCap ? (Math.round(profile.mktCap / 10000) / 100000).toFixed(2) + ' bn' : '0 bn'
    const revenue = incomeStatement && incomeStatement[0] ? (Math.round(incomeStatement[0]['Revenue'] / 10000) / 100000).toFixed(2) + ' bn' : '0 bn'
    const sharesOutstanding = enterpriseValues && enterpriseValues[0] ? (Math.round(enterpriseValues[0]['Number of Shares'] / 10000) / 100000).toFixed(2) + ' bn' : '0 bn'


    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                <Typography variant='h5' align='left' style={{ color: grey[400]}}>General Information</Typography>
            </Grid> */}
            <Grid item xs={6}>
                <GeneralInformationElement name={'Industry'} value={profile.industry} image={faIndustry} />
            </Grid>
            <Grid item xs={6}>
                <GeneralInformationElement name={'Market Cap.'} value={marketCap} image={faFileInvoiceDollar}/>
            </Grid>
            <Grid item xs={6}>
                <GeneralInformationElement name={'Sector'} value={profile.sector} image={faSquare} />
            </Grid>
            <Grid item xs={6}>
                <GeneralInformationElement name={'Shares Outst.'} value={sharesOutstanding} image={faHashtag}/>
            </Grid>
            {/* <Grid item xs={6}>
                <GeneralInformationElement name={'Employees'} value='12389349834'image={faUser} />
            </Grid> */}
            {/* <Grid item xs={6}>
                <GeneralInformationElement name={'Float (%)'} value='12389349834' image={faPercentage}/>
            </Grid> */}
            {/* <Grid item xs={6}>
                <GeneralInformationElement name={'Earnings Date'} value='12389349834' image={faCalendar}/>
            </Grid> */}
            <Grid item xs={6}>
                <GeneralInformationElement name={'Revenue'} value={revenue} image={faCoins}/>
            </Grid>
            {/* <Grid item xs={6}>
                <GeneralInformationElement name={'Ex. Div. Date'} value='12389349834' image={faCalendarDay}/>
            </Grid> */}
            {/* <Grid item xs={6}>
                <GeneralInformationElement name={'Profit Margin (%)'} value='12389349834' image={faChartPie}/>
            </Grid> */}
            <Grid item xs={6}>
                <GeneralInformationElement name={'Dividend Yield'} value={`${(dividendYield * 100).toFixed(2)}%`} image={faHandHoldingUsd}/>
            </Grid>
            <Grid item xs={6}>
                <GeneralInformationElement name={'Earnings Yield'} value={`${(earningsYield * 100).toFixed(2)}%`} image={faMoneyCheckAlt}/>
            </Grid>

        </Grid>
    )
}

GeneralInformation.propTypes = {

}


const mapStateToProps = state => {
    return {
        profiles: state.iex.profiles
    }
}

export default connect(mapStateToProps)(GeneralInformation)

