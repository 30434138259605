import axios from 'axios'

export const WILL_GET_SYMBOLS = 'WILL_GET_SYMBOLS'
export const GET_SYMBOLS_SUCCESS = 'GET_SYMBOLS_SUCCESS'
export const GET_SYMBOLS_FAILURE = 'GET_SYMBOLS_FAILURE'

export const WILL_GET_COMPANY_PROFILE = 'WILL_GET_COMPANY_PROFILE'
export const GET_COMPANY_PROFILE_SUCCESS = 'GET_COMPANY_PROFILE_SUCCESS'
export const GET_COMPANY_PROFILE_FAILURE = 'GET_COMPANY_PROFILE_FAILURE'

export const WILL_GET_HISTORICAL_EQUITY_PRICE = 'WILL_GET_HISTORICAL_EQUITY_PRICE'
export const GET_HISTORICAL_EQUITY_PRICE_SUCCESS = 'GET_HISTORICAL_EQUITY_PRICE_SUCCESS'
export const GET_HISTORICAL_EQUITY_PRICE_FAILURE = 'GET_HISTORICAL_EQUITY_PRICE_FAILURE'

export const WILL_GET_COMPANY_KEY_METRICS = 'WILL_GET_COMPANY_KEY_METRICS'
export const GET_COMPANY_KEY_METRICS_SUCCESS = 'GET_COMPANY_KEY_METRICS_SUCCESS'
export const GET_COMPANY_KEY_METRICS_FAILURE = 'GET_COMPANY_KEY_METRICS_FAILURE'

export const WILL_GET_BALANCE_SHEET_ANNUAL = 'WILL_GET_BALANCE_SHEET_ANNUAL'
export const GET_BALANCE_SHEET_ANNUAL_SUCCESS = 'GET_BALANCE_SHEET_ANNUAL_SUCCESS'
export const GET_BALANCE_SHEET_ANNUAL_FAILURE = 'GET_BALANCE_SHEET_ANNUAL_FAILURE'

export const WILL_GET_BALANCE_SHEET_QUATERLY = 'WILL_GET_BALANCE_SHEET_QUATERLY'
export const GET_BALANCE_SHEET_QUATERLY_SUCCESS = 'GET_BALANCE_SHEET_QUATERLY_SUCCESS'
export const GET_BALANCE_SHEET_QUATERLY_FAILURE = 'GET_BALANCE_SHEET_QUATERLY_FAILURE'

export const WILL_GET_CASH_FLOW_STATEMENT_ANNUAL = 'WILL_GET_CASH_FLOW_STATEMENT_ANNUAL'
export const GET_CASH_FLOW_STATEMENT_ANNUAL_SUCCESS = 'GET_CASH_FLOW_STATEMENT_ANNUAL_SUCCESS'
export const GET_CASH_FLOW_STATEMENT_ANNUAL_FAILURE = 'GET_CASH_FLOW_STATEMENT_ANNUAL_FAILURE'

export const WILL_GET_CASH_FLOW_STATEMENT_QUATERLY = 'WILL_GET_CASH_FLOW_STATEMENT_QUATERLY'
export const GET_CASH_FLOW_STATEMENT_QUATERLY_SUCCESS = 'GET_CASH_FLOW_STATEMENT_QUATERLY_SUCCESS'
export const GET_CASH_FLOW_STATEMENT_QUATERLY_FAILURE = 'GET_CASH_FLOW_STATEMENT_QUATERLY_FAILURE'

export const WILL_GET_COMPANY_RATING = 'WILL_GET_COMPANY_RATING'
export const GET_COMPANY_RATING_SUCCESS = 'GET_COMPANY_RATING_SUCCESS'
export const GET_COMPANY_RATING_FAILURE = 'GET_COMPANY_RATING_SUCCESS'


export const FILTER_SYMBOLS = 'FILTER_SYMBOLS'

export const getSymbols = () => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_SYMBOLS,
        })
        axios.get('https://api.iextrading.com/1.0/ref-data/symbols')
            .then(response => {
                return dispatch({
                    type: GET_SYMBOLS_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_SYMBOLS_FAILURE,
                })
            })
    }
}

export const filterSymbols = (searchTerm) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_SYMBOLS,
            data: searchTerm
        })
    }
}

export const getCompanyProfile = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_COMPANY_PROFILE,
        })
        axios.get(`https://financialmodelingprep.com/api/v3/company/profile/${symbol}`,{ cancelToke: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_COMPANY_PROFILE_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_COMPANY_PROFILE_FAILURE,
                })
            })
    }
}

export const getCompanyKeyMetrics = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_COMPANY_KEY_METRICS
        })
        axios.get(`https://financialmodelingprep.com/api/v3/company-key-metrics/${symbol}`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_COMPANY_KEY_METRICS_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_COMPANY_KEY_METRICS_FAILURE
                })
            })
    }
}



export const getBalanceSheetAnnual = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_BALANCE_SHEET_ANNUAL
        })
        axios.get(`https://financialmodelingprep.com/api/v3/financials/balance-sheet-statement/${symbol}`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_BALANCE_SHEET_ANNUAL_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_BALANCE_SHEET_ANNUAL_FAILURE
                })
            })
    }
}

export const getBalanceSheetQuaterly = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_BALANCE_SHEET_QUATERLY
        })
        axios.get(`https://financialmodelingprep.com/api/v3/financials/balance-sheet-statement/${symbol}?period=quarter`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type:GET_BALANCE_SHEET_QUATERLY_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_BALANCE_SHEET_QUATERLY_FAILURE
                })
            })
    }
}

export const getCashFlowStatementAnnual = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_CASH_FLOW_STATEMENT_ANNUAL
        })
        axios.get(`https://financialmodelingprep.com/api/v3/financials/balance-sheet-statement/${symbol}`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_CASH_FLOW_STATEMENT_ANNUAL_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_CASH_FLOW_STATEMENT_ANNUAL_FAILURE
                })
            })
    }
}

export const getCashFlowStatementQuaterly = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_CASH_FLOW_STATEMENT_QUATERLY
        })
        axios.get(`https://financialmodelingprep.com/api/v3/financials/balance-sheet-statement/${symbol}?period=quarter`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_CASH_FLOW_STATEMENT_QUATERLY_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_CASH_FLOW_STATEMENT_QUATERLY_FAILURE
                })
            })
    }
}

export const getCompanyRating = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_COMPANY_RATING
        })
        axios.get(`https://financialmodelingprep.com/api/v3/company/rating/${symbol}`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_COMPANY_RATING_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_COMPANY_RATING_FAILURE
                })
            })
    }
}

export const getHistoricalEquityPrice = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_HISTORICAL_EQUITY_PRICE
        })
        axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}`, { cancelToke: signal.token})
            .then(response => {
                return dispatch({
                    type: GET_HISTORICAL_EQUITY_PRICE_SUCCESS,
                    data: response.data
                })
            }).catch(error => {
                return dispatch({
                    type: GET_HISTORICAL_EQUITY_PRICE_FAILURE
                })
            })
    }
}