import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { grey, green, red } from '@material-ui/core/colors';
import { useMediaQuery } from '@material-ui/core';



function CandlePatternChart(props) {

    const matches = useMediaQuery('(max-width:500px)')
    const { name, data, length } = props

    var categoryData = [];
    var values = []

    for (var i = data.length-length; i < data.length; i++) {
        categoryData.push(data[i]['date']);
        values.push([ data[i]['open'], data[i]['close'], data[i]['low'], data[i]['high']])
        
    }

    const option = {
            title: {
                text: name,
                left: 0,
                show: false
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line'
                },
                show: false
            },
            legend: {
                data: [],
                show: false
            },
            grid: [{
                top: '0px',
                left: '20px',
                right: '20px',
                height: matches ? '100px' : '150px'
            }],
            xAxis: [{
                show: false,
                type: 'category',
                data: categoryData,
                scale: true,
                boundaryGap : false,
                splitLine: {show: false},
                splitNumber: 20,
                min: 'dataMin',
                max: 'dataMax',
                axisLine: {
                    onZero: false,
                    show: true,
                    lineStyle: {
                        color: grey[400]
                    },
                },
                nameTextStyle: {
                    color: grey[400]
                },
            }],
            yAxis: [{
                show: false,
                scale: true,
                position: 'right',
                splitArea: {
                    show: false,
                },
                boundaryGap: ['10%', '10%'],
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: grey[400]
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: grey[400]
                    },
                },
                nameTextStyle: {
                    color: grey[400]
                },
            }],
            series: [
                {
                    name: name,
                    type: 'candlestick',
                    data: values,
                    itemStyle: {
                        normal: {
                            color: green[400],
                            color0: red[400],
                            borderColor: green[400],
                            borderColor0: red[400],
                        }
                    },
                },
            ]
    }

    return (
        <ReactEcharts option={option} style={{ height: matches ? 100 : 150, width: matches ? 100 : 150 }} />
    )
}


export default CandlePatternChart