import { enqueueSnackbar } from "./notificationActions";
import ReactGA from 'react-ga'
import { GA_IDENTIFIER } from "../../config/config";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP_SUCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'

export const SUBSCRIBED = 'SUBSCRIBED'
export const UNSUBSCRIBED = 'UNSUBSCRIBED'


ReactGA.initialize('UA-140771558-1')

export const updatePassword = (password) => {
    return (dispatch, getState, {getFirebase}) => {
        var user = getFirebase().auth().currentUser

        user.updatePassword(password)
            .then(() => {
                dispatch(enqueueSnackbar({
                    message: 'Your Password has been updated successfully!',
                    options: {
                        variant: 'success'
                    }
                }))
            })
            .catch((error) => {
                dispatch(enqueueSnackbar({
                    message: error.message,
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}

export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const persistence = credentials.rememberChecked ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION

        firebase.auth().setPersistence(persistence)
            .then(
                firebase.auth().signInWithEmailAndPassword(
                    credentials.email,
                    credentials.password
                ).then(() => {
                    ReactGA.event({
                        category: 'auth',
                        action: 'sign_in',
                        label: GA_IDENTIFIER,
                    })
                    dispatch({
                        type: LOGIN_SUCCESS,
                        error: null
                    })
                    dispatch(enqueueSnackbar({
                        message: 'Sign in successful!',
                        options: {
                            variant: 'success'
                        }
                    }))
                }).catch((error) => {
                    dispatch(enqueueSnackbar({
                        message: error.message,
                        options: {
                            variant: 'error'
                        }
                    }))

                    dispatch({
                        type: LOGIN_ERROR,
                        error: error
                    })
                })
            )
    }
}

export const reauthenticateUser = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        var user = getFirebase().auth().currentUser;
        var creds = getFirebase().auth.EmailAuthProvider.credential(user.email, credentials.password)

        user.reauthenticateWithCredential(creds)
        .then(() => {
            dispatch(enqueueSnackbar({
                message: 'Authentification successful',
                options: {
                    variant: 'success'
                }
            }))
        }).catch((error) => {
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    variant: 'error'
                }
            }))
        })
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        firebase.auth().signOut()
        .then(() => {
            ReactGA.event({
                category: 'auth',
                action: 'sign_out',
                label: GA_IDENTIFIER,
            })
            dispatch(enqueueSnackbar({
                message: 'Sign out successful!',
                options: {
                    variant: 'success'
                }
            }))
        })
        .catch((error) => {
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    variant: 'error'
                }
            }))
        })
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp) => {
            ReactGA.event({
                category: 'auth',
                action: 'sign_up',
                label: GA_IDENTIFIER,
            })
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                username: `${newUser.firstName} ${newUser.lastName[0]}`,
                initials: newUser.firstName[0] + newUser.lastName[0],
            })
        }).then(() => {
            dispatch(enqueueSnackbar({
                message: "Yor account was created successfully!",
                options: {
                    variant: 'success'
                }
            }))
            dispatch({
                type: SIGNUP_SUCESS,
                error: null
            })
        }).catch(error => {
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    variant: 'error'
                }
            }))

            dispatch({
                type: SIGNUP_ERROR,
                error: error
            })
        })


    }
}

export const deleteUser = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()

        var user = firebase.auth().currentUser

        user.delete()
            .then(() => {
                ReactGA.event({
                    category: 'auth',
                    action: 'delete_account',
                    label: GA_IDENTIFIER,
                })
                return dispatch(enqueueSnackbar({
                    message: 'Your account has been deleted successfully',
                    options: {
                        variant: 'info'
                    }
                }))
            })
            .catch((error) => {
                return dispatch(enqueueSnackbar({
                    message: 'There was an error deleting your account, please try later again',
                    options: {
                        variant: 'error'
                    }
                }))
            })

    }
}

export const resetPassword = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch(enqueueSnackbar({
                    message: 'An Email with a Password Reset Link was sent to the adress!',
                    options: {
                        variant: 'info'
                    }
                }))
            })
            .catch((error) => {
                dispatch(enqueueSnackbar({
                    message: error.message,
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}

export const subscribeToNewsletter = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        var subscribeToMailChimp = firebase.functions().httpsCallable('subscribeToMailChimp')
        return subscribeToMailChimp({ email: email })
            .then((result) => {
                ReactGA.event({
                    category: 'auth',
                    action: 'subscribe_newsletter',
                    label: GA_IDENTIFIER,
                })
                if (result.data.success === false) {
                    if (result.data.error.title === "Member Exists") {
                        return dispatch(enqueueSnackbar({
                            message: "We apriciate the effort but you already subscribed!",
                            options: {
                                variant: 'info'
                            }
                        }))
                    } else {
                        throw dispatch(enqueueSnackbar({
                            message: result.data.error.detail,
                            options: {
                                variant: 'warning'
                            }
                        }))
                    }
                } else {
                    dispatch(enqueueSnackbar({
                        message: 'You successfully subscribed to the E-Mail List',
                        options: {
                            variant: 'success'
                        }
                    }))
                    return dispatch({
                        type: SUBSCRIBED
                    })
                }
            })
            .catch((error) => {
                throw error
            })
    }
}

export const unsubscribeFromNewsletter = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        var unsubscribeFromMailChimp = firebase.functions().httpsCallable('unsubscribeFromMailChimp')
        unsubscribeFromMailChimp({ email: email })
            .then((result) => {
                ReactGA.event({
                    category: 'auth',
                    action: 'unsubscribe_newsletter',
                    label: GA_IDENTIFIER,
                })
                if (result.data.success === false) {
                    console.log(result.data.error)
                    // if (result.data.error.title === "Member Exists") {
                    //     dispatch(enqueueSnackbar({
                    //         message: "We are unable to unsubscribe you since you are not on our list!",
                    //         options: {
                    //             variant: 'info'
                    //         }
                    //     }))
                    // } else {
                    //     dispatch(enqueueSnackbar({
                    //         message: result.data.error.detail,
                    //         options: {
                    //             variant: 'warning'
                    //         }
                    //     }))
                    // }
                } else {
                    dispatch(enqueueSnackbar({
                        message: 'You successfully unsubscribed from the E-Mail List',
                        options: {
                            variant: 'success'
                        }
                    }))
                    return dispatch({
                        type: UNSUBSCRIBED
                    })
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }
}

export const checkNewsletterSubscriptionStatus = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        var checkSubscriptionToMailChimp = firebase.functions().httpsCallable('checkSubscriptionToMailChimp')
        checkSubscriptionToMailChimp({ email: email })
            .then((result) => {
                if (result.data.success === false) {
                    return dispatch({
                        type: UNSUBSCRIBED
                    })
                } else {
                    if (result.data.data.status === 'subscribed') {
                        return dispatch({
                            type: SUBSCRIBED
                        })
                    } else {
                        console.error(result.data)
                        return dispatch({
                            type: UNSUBSCRIBED
                        })
                    }
                }
            })
            .catch((error) => {
                return dispatch({
                    type: UNSUBSCRIBED
                })
            })
    }
}

export const updateProfile = (profile) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        firebase.updateProfile(
            {
                firstName: profile.firstName ? profile.firstName : "",
                lastName: profile.lastName ? profile.lastName : "",
                addressLine1: profile.addressLine1 ? profile.addressLine1 : "",
                addressLine2: profile.addressLine2 ? profile.addressLine2 : "",
                city: profile.city ? profile.city : "",
                postcode: profile.postcode ? profile.postcode : "",
                country: profile.country ? profile.country : "",
                username: profile.username ? profile.username : ""
            })
            .then(() => {
                dispatch(enqueueSnackbar({
                    message: 'Your Profile was updated successfully!',
                    options: {
                        variant: 'success'
                    }
                }))
            })
            .catch((error) => {
                dispatch(enqueueSnackbar({
                    message: error.message,
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}

export const updateEmail = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        var user = getFirebase().auth().currentUser;

        user.updateEmail(email)
            .then(() => {
                dispatch(enqueueSnackbar({
                    message: 'Your Email has been updated successfully!',
                    options: {
                        variant: 'success'
                    }
                }))
            })
            .catch((error) => {
                dispatch(enqueueSnackbar({
                    message: error.message,
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}

export const sendSp1Mail = (dest) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        var send = firebase.functions().httpsCallable('sendSp1Mail')
        send({ dest: dest })
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error(error)
            })
    }
}

export const sendContactMail = (email, subject, content) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        var send = firebase.functions().httpsCallable('sendContactMail')
        return send({ email: email, subject: subject, content: content })
            .then((result) => {
                return dispatch(enqueueSnackbar({
                    message: 'You Email has been sent!',
                    options: {
                        variant: 'success'
                    }
                }))
            })
            .catch((error) => {
                return dispatch(enqueueSnackbar({
                    message: 'There was an error sending your email. Please try later again!',
                    options: {
                        variant: 'error'
                    }
                }))
            })
    }
}