import React from "react";
import "./App.css";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import OptionStrategySelector from "./views/OptionStrategySelector";
import CookieConsentPopUp from "./components/CookieConsentPopUp";
const hist = createBrowserHistory();

function App() {
  return (
    <Router history={hist}>
      
      <OptionStrategySelector/>
    </Router>
  );
}

export default App;
