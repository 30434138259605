import React, { useState, useEffect } from 'react'
// import Footer from '../components/home/Footer';
import { Grid, Paper, IconButton, Typography, Toolbar, AppBar, Dialog, Button, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';
import { grey, green } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import AliceCarousel from 'react-alice-carousel'
import "react-alice-carousel/lib/alice-carousel.css"
import { enqueueSnackbar } from '../store/actions/notificationActions';
import { addAppToMyApps, getSimilarApps } from '../store/actions/appActions';
import ReactGA from 'react-ga'

import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Done'
import StarIcon from '@material-ui/icons/Star'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
// import NewSubscriptionDialog from '../components/subscriptions/NewSubscriptionDialog';
import AppItem from '../components/Apps/AppItem';
// import ReviewTile from '../components/reviews/ReviewTile';
// import ReviewDialog from '../components/reviews/ReviewDialog';
import SignInDialog from '../components/auth/SignInDialog';
// import PayNowDialog from '../components/payment/PayNowDialog';

ReactGA.initialize('UA-140771558-1')

function FeatureListItem(props) {
    const {text} = props
    return (
        <ListItem>
            <ListItemIcon>
                <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={text}/>
        </ListItem>
    )
}

function AppDetailPage(props) {

    

    const { 
        selectedApp, 
        calledApp, 
        userApps, 
        appSubscription, 
        open, 
        handleClose, 
        getSimilarApps, 
        similarApps, 
        match, 
        auth,
        enqueueSnackbar
    } = props

    const app = selectedApp ? selectedApp : calledApp ? calledApp[0] : null

    const [calledFromWeb, setCalledFromWeb] = useState(false)
    const [reviewDialogOpen, setReviewDialogOpen] = useState(false)
    const [signInDialogOpen, setSignInDialogOpen] = useState(false)
    const [newSubscriptionDialogOpen, setNewSubscriptionDialogOpen] = useState(false)

    useEffect(() => {
        // TODO: get called twice needs to be fixed
        if (app && app.mainCategory) {
            getSimilarApps('mainCategory', app.mainCategory)
        }
    }, [app])

    // Check if page got called from link
    useEffect(() => {
        if(match) {
            setCalledFromWeb(true)
        }
    }, [match])

    const closeNewSubscriptionDialog = () => {
        setNewSubscriptionDialogOpen(false)
    }

    const openNewSubscriptionDialog = () => {
        setNewSubscriptionDialogOpen(true)
    }

    const handleOnDragStart = e => e.preventDefault()

    const addToMyApps = () => {
        if (props.auth.uid) {
            props.addAppToMyApps(app)
        } else { 
            enqueueSnackbar({
                message: 'You have to be signed in in order to add Apps to My Apps!',
                options: {
                    variant: 'warning'
                }
            })
            setSignInDialogOpen(true)
        }
    }

    const start = () => {
        window.open(app.link)
    }

    const openReviewDialog = () => {
        if(auth && auth.uid) {
            setReviewDialogOpen(true)
        } else {
            enqueueSnackbar({
                message: "You have to be signed in in order to review Apps!",
                options: {
                    variant: 'warning'
                }
            })
            setSignInDialogOpen(true)
        }
    }

    if (!app) {
        return null
    }

    const inMyApps = userApps ? userApps.map(t => t.appId).includes(app.id) : false
    const isSubscribed = appSubscription ? appSubscription.map(t=> t.appId).includes(app.id): false
    const isPaid = app && app.cost !== 'free'

    var addButton = null

    if (isPaid) {
        addButton = (isSubscribed ?
            <Button coloe='primary' disabled style={{ marginRight: 8}}>
                <DoneIcon/> Bought
            </Button>
            :
            <Button color='primary' onClick={openNewSubscriptionDialog} style={{ margingRight: 8 }}>
                <AddShoppingCartIcon/> Buy Now
            </Button>
        )
    } else {
        addButton = (inMyApps ?
            <Button color='primary' disabled={true} style={{ marginRight: 8}}>
                <DoneIcon/> In My Apps
            </Button>
            :   
            <Button color='primary' onClick={addToMyApps} style={{ marginRight: 8}}>
                <AddIcon/> Free
            </Button>
        )
    }

    const features = app && app.features ? app.features.map(f => <FeatureListItem key={f}  text={f} />) : null
    return (
        <Dialog fullScreen open={open || calledFromWeb}>
            <AppBar position='fixed'>
                <Toolbar>
                    <div style={{ flexGrow: 1}} />
                    <IconButton edge='end' color="inherit" aria-label="Back" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ paddingTop: 64, overflowX: 'hidden' }}>
                <Grid container style={{ padding: 16 }} spacing={2}>
                    <Grid container item xs={12} spacing={2} justify='space-between'>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Paper  style={{ height: 60, width: 60, borderRadius: 8, backgroundColor: '#ffffff' }}>
                                        <img src={app.logo} alt='' style={{ height: 'auto', width: 50, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <Typography variant='h6' color='textPrimary' style={{ fontWeight: 'bold', height: 24 }}>{app.name}</Typography>
                                    <Typography variant='caption' color='textSecondary' style={{ height: 10}}>{app.mainCategory} </Typography>
                                    <Grid container spacing={0}>
                                        <Grid item>
                                            <StarIcon fontSize='small' style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)'}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='caption'> 4.5</Typography>
                                        </Grid>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div>
                                
                            </div>
                        </Grid>
                        <Grid item>
                            {addButton}
                            {!isPaid || isSubscribed ?
                                <Button color='primary' variant='outlined' onClick={start} style={{ color: green[400], borderColor: green[400]}}>
                                    Start
                                </Button>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justify='space-between'>
                        <AliceCarousel 
                            mouseDragEnabled
                            responsive = {{
                                0: { items: 1 },
                                660: { items: 2 },
                                980: { items: 3 },
                                1300: { items: 4 },
                                1620: { items: 5 },
                                }}
                            items={app.image.map(image => <img src={image} alt="" onDragStart={handleOnDragStart} style={{width: '300px', height: '400px', objectFit: 'cover' }}/>)}
                        /> 
                        {/* {app.image.map(image => <img src={image} alt="" onDragStart={handleOnDragStart} style={{width: 320, height: 'auto'}}/>)}
                        </AliceCarousel> */}

                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='h5' style={{ fontWeight: 'bold' }} >Features</Typography>
                        <List dense={true}>
                            {features}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='h5' style={{ fontWeight: 'bold' }} >Description</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='body1'>{app.descriptionLong}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='h5' style={{ fontWeight: 'bold' }} >Rating & Reviews</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5' color='textPrimary' style={{ fontWeight: 'bold' }} display='inline'>
                            5.0
                            </Typography>
                        <Typography variant='caption' color='textPrimary' style={{ fontWeight: 'bold' }} display='inline'> out of 5.0</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing= {2}>
                        <AliceCarousel
                            mouseDragEnabled
                            responsive = {{
                                0: { items: 1 },
                                660: { items: 2 },
                                980: { items: 3 },
                                1300: { items: 4 },
                                1620: { items: 5 },
                            }}
                            autoPlay={true}
                            autoPlayInterval={3000}
                            buttonsDisabled={true}
                            items={
                                [...Array(5).keys()].map(i => {
                                return (
                                    <div style={{ padding: 4 }} key={i}>
                                        {/* <ReviewTile /> */}
                                    </div>
                                )}
                                )
                            }
                        />

                    </Grid>
                    <Grid item>
                        <Button variant='outlined' color='primary'>Show All Reviews</Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={openReviewDialog}>Write Review</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='h5' style={{ fontWeight: 'bold' }} >You might also Like</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <AliceCarousel
                        mouseDragEnabled
                        responsive = {{
                            0: { items: 1 },
                                660: { items: 2 },
                                980: { items: 3 },
                                1300: { items: 4 },
                                1620: { items: 5 },
                        }}
                        autoPlay={true}
                        autoPlayInterval={3000}
                        buttonsDisabled={true}
                        items={
                            similarApps.map(app => {
                            return (
                                <div key={app.id} style={{ padding: 4 }}>
                                    <AppItem
                                        app={app}
                                        onClick={() => this.onAppItemClick(app)}
                                    />
                                </div>
                            )}
                            )
                        }
                    />

                    </Grid>

                    <Grid item xs={12}>
                        <Typography color='textPrimary' variant='h5' style={{ fontWeight: 'bold' }} gutterBottom >Information</Typography>
                        <div style={{ height: 1, width: '100%', backgroundColor: grey[400] }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant='body1' color='textSecondary'>Provider</Typography>
                        <Typography variant='h6' color='textPrimary'>Moving Indicators</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant='body1' color='textSecondary'>Category</Typography>
                        <Typography variant='h6' color='textPrimary'>{app.mainCategory}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant='body1' color='textSecondary'>Languages</Typography>
                        <Typography variant='h6' color='textPrimary'>English</Typography>
                    </Grid>
                </Grid>


                {/* <PayNowDialog open={newSubscriptionDialogOpen} onClose={closeNewSubscriptionDialog} app={app} uid={auth && auth.uid ? auth.uid : 'asdf'}/> */}
                {/* <ReviewDialog open={reviewDialogOpen} onClose={() => setReviewDialogOpen(false)} appId={app.id}/> */}
                <SignInDialog open={signInDialogOpen} onClose={() => setSignInDialogOpen(false)} />
                {/* <Footer /> */}
            </div>
        </Dialog>

    )
}

const mapStateToProps = (state) => {
    return {
        // selectedApp: state.firestore.ordered.sa,
        auth: state.firebase.auth,
        userApps: state.firestore.ordered.ua,
        appSubscription: state.firestore.ordered.as,
        calledApp: state.firestore.ordered.sa,
        similarApps: state.app.similarApps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enqueueSnackbar: msg => dispatch(enqueueSnackbar(msg)),
        addAppToMyApps: app => dispatch(addAppToMyApps(app)),
        getSimilarApps: (key, value) => dispatch(getSimilarApps(key, value))
        
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        const fbArray = []
        if(props.uid && props.selectedApp) {
            fbArray.push(
                { 
                    collection: 'userApps',
                    storeAs: 'ua',
                    where: ['userId', '==', props.uid],
                }
            )
            fbArray.push(
                {
                    collection: 'appSubscriptions',
                    storeAs: 'as',
                    doc:  `${props.uid}_${props.selectedApp.id}`
                }
            )
        } else if (props.match) {
            fbArray.push(
                    {
                        collection: 'apps',
                        doc: props.match.params.id,
                        storeAs: 'sa',
                        
                    }
            )
        }

        return fbArray
    })
)(AppDetailPage)