import { WILL_GET_ENTERPRISE_VALUES_ANNUAL, WILL_GET_ENTERPRISE_VALUES_QUATERLY, GET_ENTERPRISE_VALUES_ANNUAL_SUCCESS, GET_ENTERPRISE_VALUES_ANNUAL_FAILURE, GET_ENTERPRISE_VALUES_QUATERLY_SUCCESS, GET_ENTERPRISE_VALUES_QUATERLY_FAILURE } from "../actions/enterpriseValuesActions"


const initState = {
    enterpriseValuesAnnual: [],
    enterpriseValuesQuaterly: [],
    fetching: false
}

const enterpriseValuesReducer = (state=initState, action) => {
    switch (action.type) {
        case WILL_GET_ENTERPRISE_VALUES_QUATERLY:
            return {
                ...state,
                fetching: true
            }

        case GET_ENTERPRISE_VALUES_QUATERLY_SUCCESS:
            return {
                ...state,
                fetching: false,
                enterpriseValuesQuaterly: action.data
            }
        
        case GET_ENTERPRISE_VALUES_QUATERLY_FAILURE:
            return {
                ...state,
                fetching: false
            }


        case WILL_GET_ENTERPRISE_VALUES_ANNUAL:
            return {
                ...state,
                fetching: true
            }

        case GET_ENTERPRISE_VALUES_ANNUAL_SUCCESS:
            return {
                ...state,
                fetching: false,
                enterpriseValuesAnnual: action.data
            }
        
        case GET_ENTERPRISE_VALUES_ANNUAL_FAILURE:
            return {
                ...state,
                fetching: false
            }

        default:
            return state
    }
}

export default enterpriseValuesReducer