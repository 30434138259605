import axios from 'axios'

export const WILL_GET_ENTERPRISE_VALUES_ANNUAL = 'WILL_GET_ENTERPRISE_VALUES_ANNUAL'
export const GET_ENTERPRISE_VALUES_ANNUAL_SUCCESS = 'GET_ENTERPRISE_VALUES_ANNUAL_SUCCESS'
export const GET_ENTERPRISE_VALUES_ANNUAL_FAILURE = 'GET_ENTERPRISE_VALUES_ANNUAL_FAILURE'

export const WILL_GET_ENTERPRISE_VALUES_QUATERLY = 'WILL_GET_ENTERPRISE_VALUES_QUATERLY'
export const GET_ENTERPRISE_VALUES_QUATERLY_SUCCESS = 'GET_ENTERPRISE_VALUES_QUATERLY_SUCCESS'
export const GET_ENTERPRISE_VALUES_QUATERLY_FAILURE = 'GET_ENTERPRISE_VALUES_QUATERLY_FAILURE'


export const getEnterpriseValuesAnnual = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_ENTERPRISE_VALUES_ANNUAL
        })
        axios.get(`https://financialmodelingprep.com/api/v3/enterprise-value/${symbol}`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type: GET_ENTERPRISE_VALUES_ANNUAL_SUCCESS,
                    data: response.data.enterpriseValues
                })
            }).catch(error => {
                return dispatch({
                    type: GET_ENTERPRISE_VALUES_ANNUAL_FAILURE
                })
            })
    }
}

export const getEnterpriseValuesQuaterly = (symbol, signal) => {
    return (dispatch) => {
        dispatch({
            type: WILL_GET_ENTERPRISE_VALUES_QUATERLY
        })
        axios.get(`https://financialmodelingprep.com/api/v3/enterprise-value/${symbol}?period=quarter`, { cancelToken: signal.token })
            .then(response => {
                return dispatch({
                    type:GET_ENTERPRISE_VALUES_QUATERLY_SUCCESS,
                    data: response.data.enterpriseValues
                })
            }).catch(error => {
                return dispatch({
                    type: GET_ENTERPRISE_VALUES_QUATERLY_FAILURE
                })
            })
    }
}