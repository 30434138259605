import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import { firebaseReducer } from "react-redux-firebase";
import iexReducer from "./iexReducer";
import incomeStatementReducer from "./incomeStatementReducer";
import enterpriseValuesReducer from "./enterpriseValuesReducer";
import notificationReducer from "./notificationReducer";
import appReducer from "./appReducer";
import searchResultReducer from "./searchResultReducer";
import dataReducer from "./dataReducer";


const rootReducer = combineReducers({
  firebase: firebaseReducer,
  iex: iexReducer,
  app: appReducer,
  incomeStatement: incomeStatementReducer,
  enterpriseValues: enterpriseValuesReducer,
  notifications: notificationReducer,
  auth: authReducer,
  firestore: firestoreReducer,
  search: searchResultReducer,
  data: dataReducer
});

export default rootReducer;
