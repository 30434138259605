import { WILL_GET_ALL_APPS, GET_ALL_APPS_SUCCESS, GET_ALL_APPS_FAILURE, WILL_FILTER_APP, FILTER_APPS, WILL_GET_SIMILAR_APPS, GET_SIMILAR_APPS_SUCCESS, GET_SIMILAR_APPS_FAILURE } from "../actions/appActions"

const initState = {
    allApps: [],
    filteredApps: [],
    similarApps: [],
    gettingApps: false,
    gettingSimilarApps: false,
    filteringApps: false,

}

const appReducer = (state=initState, action) => {
    switch(action.type) {
        case WILL_GET_ALL_APPS:
            return { ...state, gettingApps: true}

        case GET_ALL_APPS_SUCCESS:
            return { ...state, gettingApps: false, allApps: action.data}

        case GET_ALL_APPS_FAILURE:
            return { ...state, gettingApps: false}

        case WILL_FILTER_APP:
            return { ...state, filteredApps: true}

        case FILTER_APPS:
            const searchTerm = action.data.toLowerCase()
            const filteredApps = []
            var len = 0
            var index = 0
            const lenApps = state.allApps.length

            while (len < 3 && index < lenApps) {
                const app = state.allApps[index]
                if (app.name.toLowerCase().includes(searchTerm)) {
                    filteredApps.push(app)
                    len += 1
                }
                index += 1
            }

            return { ...state, filteredApps: false, filteredApps: filteredApps}

        case WILL_GET_SIMILAR_APPS:
            return {...state, gettingSimilarApps: true}
        
        case GET_SIMILAR_APPS_SUCCESS:
            return {
                ...state,
                similarApps: action.data,
                gettingSimilarApps: false
            }

        case GET_SIMILAR_APPS_FAILURE:
            return {
                ...state,
                similarApps: [],
                gettingSimilarApps: false
            }

        default:
            return state
    }
}

export default  appReducer