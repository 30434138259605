import React, { Fragment, useEffect, useState } from "react";
import BackgroundImage from "../../assets/img/MyApps/defaultBackground.png";
import { Helmet } from "react-helmet";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  withStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  getCompanyProfile,
  getHistoricalEquityPrice,
  getCompanyKeyMetrics,
  getCompanyRating
} from "../../store/actions/iexActions";
import { grey, green, red } from "@material-ui/core/colors";
import PriceChart from "./PriceChart";
import SwipeableViews from "react-swipeable-views";
import Axios from "axios";
import CandleStickPatternsDialog from "./CandleStickPatternsDialog";
import GeneralInformation from "./GeneralInformation";
import { getIncomeStatementAnnual } from "../../store/actions/incomeStatementActions";
import { getEnterpriseValuesAnnual } from "../../store/actions/enterpriseValuesActions";
import { calculate } from "../../store/actions/dataActions";
import CompanyRating from "./CompanyRating";
import Technical from "./Technical";
import GetRealtimeData from "../presales/GetRealtimeData";
import FinancialWidget from "../Widgets/FinancialWidget";
import TechnicalAnalysisWidget from "../Widgets/TechnicalAnalysisWidget";

function EquityHeading(props) {
  const { profile, symbol } = props;
  const matches = useMediaQuery("(max-width:500px)");

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Paper
          style={{
            height: matches ? 48 : 60,
            width: matches ? 48 : 60,
            borderRadius: 8,
            backgroundColor: "#ffffff"
          }}
        >
          <img
            src={profile.image}
            alt=""
            style={{
              height: "auto",
              width: matches ? 40 : 52,
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          />
        </Paper>
      </Grid>
      <Grid item>
        <Typography
          variant={matches ? "body1" : "h5"}
          style={{ color: grey[600] }}
        >
          {profile.companyName} ({symbol}) <br />
        </Typography>
        <Typography
          variant={matches ? "body1" : "h5"}
          style={{ fontWeight: "bold", paddingRight: 8, color: grey[600] }}
          display="inline"
        >
          {profile.price}
        </Typography>
        <Typography
          variant="caption"
          color="textPrimary"
          style={{
            fontWeight: "bold",
            color: profile.changes > 0 ? green[400] : red[400]
          }}
          display="inline"
        >
          {profile.changes} {profile.changesPercentage}
        </Typography>
      </Grid>
    </Grid>
  );
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      //maxWidth: 40,
      width: "100%",
      backgroundColor: grey[600]
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: grey[600],
    //fontWeight: theme.typography.fontWeightRegular,
    //fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

function Stocks(props) {
  const { symbol } = props;
  // const [symbol, setSymbol] = useState('H')
  // if(window.location.href.split('/')[4]!=undefined){
  //   setSymbol(window.location.href.split('/')[4])
  // }
  const {
    profiles,
    historicalEquityPrice,
    trend,
    momentum,
    candle,
    companyKeyMetrics,
    incomeStatement,
    enterpriseValuesAnnual,
    companyRating
  } = props;

  const [
    candleStickPatternsDialogOpen,
    setCandleStickPatternsDialogOpen
  ] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const profile = profiles[symbol] ? profiles[symbol] : {};
  useEffect(() => {
    const signal = Axios.CancelToken.source();

    if (!profiles[symbol]) {
      props.getCompanyProfile(symbol, signal);
    }

    props.getHistoricalEquityPrice(symbol, signal);
    props.getCompanyKeyMetics(symbol, signal);
    props.getIncomeStatementAnnual(symbol, signal);
    props.getEnterpriseValuesAnnual(symbol, signal);
    props.getCompanyRating(symbol, signal);

    return () => {
      signal.cancel();
    };
  }, [symbol]);

  function handleCandleStickPatternsDialogOpen() {
    setCandleStickPatternsDialogOpen(true);
  }

  function handleCandleStickPatternsDialogClose() {
    setCandleStickPatternsDialogOpen(false);
  }

  function handleChangeIndex(index) {
    setTabIndex(index);
  }

  function handleChange(event, newValue) {
    setTabIndex(newValue);
  }

  return (
    <Fragment>
      <Paper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Moving Indicators - Search</title>
          <meta
            name="search"
            content="Discover and analyze all the equities in the trading universe."
          />
        </Helmet>
        <div
          style={{
            padding: 16,
            overflowX: "hidden",
            overflowY: "hidden"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid item xs={12}>
                <EquityHeading profile={profile} symbol={props.symbol} />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ backgroundColor: "rgba(255,255,255,0)", padding: 0 }}
                >
                  {/* <Paper style={{ padding: 16 }}> */}
                  <PriceChart
                    symbol={props.symbol}
                    data={historicalEquityPrice}
                  />
                  {/* </Paper> */}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} style={{ height: "80vh" }}>
              <StyledTabs
                value={tabIndex}
                onChange={handleChange}
                //indicatorColor='primary'
                //textColor='primary'
                variant="fullWidth"
                style={{
                  paddingBottom: 16
                }}
              >
                <StyledTab label={"Technical"} />
                <StyledTab label={`Fundamental`} />
                <StyledTab label={`General`} />
              </StyledTabs>
              <SwipeableViews
                index={tabIndex}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents
              >
                <div style={{ overflow: "hidden" }}>
                  <TechnicalAnalysisWidget symbol={props.symbol} />
                </div>
                <div style={{ overflow: "hidden" }}>
                  <FinancialWidget symbol={props.symbol} />
                </div>
                <div style={{ overflow: "hidden", margin: "32px" }}>
                  <GeneralInformation
                    profile={profile}
                    metrics={companyKeyMetrics}
                    incomeStatement={incomeStatement}
                    enterpriseValues={enterpriseValuesAnnual}
                  />
                </div>
              </SwipeableViews>
            </Grid>
          </Grid>
        </div>
        <CandleStickPatternsDialog
          open={candleStickPatternsDialogOpen}
          onClose={handleCandleStickPatternsDialogClose}
        />
      </Paper>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    profiles: state.iex.profiles,
    fetchingProfile: state.iex.fetchingProfile,
    historicalEquityPrice: state.iex.historicalEquityPrice,
    trend: state.iex.trend,
    momentum: state.iex.momentum,
    candle: state.iex.candle,
    companyKeyMetrics: state.iex.companyKeyMetrics,
    incomeStatement: state.incomeStatement.incomeStatementAnnual,
    enterpriseValuesAnnual: state.enterpriseValues.enterpriseValuesAnnual,
    companyRating: state.iex.companyRating,
    symbol: state.search.symbol,
    autoFill: state.data.autoFill
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompanyProfile: (symbol, signal) =>
      dispatch(getCompanyProfile(symbol, signal)),
    getHistoricalEquityPrice: (symbol, signal) =>
      dispatch(getHistoricalEquityPrice(symbol, signal)),
    getCompanyKeyMetics: (symbol, signal) =>
      dispatch(getCompanyKeyMetrics(symbol, signal)),
    getIncomeStatementAnnual: (symbol, signal) =>
      dispatch(getIncomeStatementAnnual(symbol, signal)),
    getEnterpriseValuesAnnual: (symbol, signal) =>
      dispatch(getEnterpriseValuesAnnual(symbol, signal)),
    getCompanyRating: (symbol, signal) =>
      dispatch(getCompanyRating(symbol, signal))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);
