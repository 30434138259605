import React, { Component } from "react";
import { Grid, Typography, Paper, MenuItem, MenuList,styled } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProfitAndLossDiagram from "./ProfitAndLossDiagram";
import { recordAppAction } from "../../store/actions/dataActions";
import moment from "moment";
import {updateData} from "../../store/actions/dataActions"
import { PRIMARY } from "../../config/config";

const styles = theme => ({
  menuList: {
    maxHeight: 608,
    overflowX: "auto",
    padding: 16
  }
});
const StyledMenuItem = styled(MenuItem)({
  "&:hover": {
    backgroundColor: PRIMARY,
    color: "#ffffff"
  }
});

class Strategies extends Component {
  state = { smooth: false };
  handleChoosenMenuItem = event => {
    this.props.updateData("optionStrategy",event.target.id)
    const {
      MarketOutlook,
      VolatilityView,
      TimeErosion,
      Dividends,
      Interest,
      Profit,
      Loss,
    } = this.props.parameters;

    this.props.recordAppAction({
      app: "strategy_master",
      action: {
        marketOutlook: MarketOutlook,
        volatilityView: VolatilityView,
        timeErosion: TimeErosion,
        dividends: Dividends,
        interest: Interest,
        profit: Profit,
        loss: Loss,
        strategy: event.target.outerText,
      },
      success: true,
      timestamp: moment.utc().valueOf()
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={3}
          container
          spacing={1}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Strategies
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={5}  className={classes.menuList} style={{borderRadius: 30}}>
              <MenuList>
                {this.props.optionStrategies.map(name => (
                  <StyledMenuItem
                    id={name}
                    key={name}
                    value={name}
                    onClick={this.handleChoosenMenuItem}
                  >
                    {name}
                  </StyledMenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} container spacing={1} alignContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Profit & Loss Diagram
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ProfitAndLossDiagram strategies={this.state} optionStrategy={this.props.optionStrategy}/>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    optionStrategy: state.data.optionStrategy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    recordAppAction: data => dispatch(recordAppAction(data)),
    updateData: (name,valueOfAttribute) => dispatch(updateData(name,valueOfAttribute))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Strategies)));
