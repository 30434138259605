import React, { Component, useState } from "react";
import { Grid, Typography, Paper, Hidden, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import SearchField from "./SearchField";
import Menu from '@material-ui/core/Menu';
import { PRIMARY, OH_LOGO } from "../../config/config";
const styles = theme => ({
  image: {
    width: "auto",
    height: 60
  },
  imageForSmallScreen:{
    width: "auto",
    height: 60
  },
  container: {
    flexGrow: 1,
    padding: 16
  }
});

  function Logo(props) {
    const [anchorQuickSearch, setAnchorQuickSearch] = useState(null);
    const quickResultsOpen = Boolean(anchorQuickSearch)
    const { classes } = props;
    const handleSearchResultsClose = (event) => {
      setAnchorQuickSearch(null);
    }
    const renderQuickSearchResults = (
      <Menu
        anchorEl={anchorQuickSearch}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={quickResultsOpen}
        onClose={handleSearchResultsClose}
      ></Menu>
    );
    return (
      <Paper elevation={5} className={classes.container} style={{borderRadius: 30 }}>
          <Grid container alignItems="center" item xs={12} spacing={1} justify="space-between">
           
                <Hidden smUp>
                  <Grid item>
                    <img src={logo} alt="logo" className={classes.imageForSmallScreen} />
                  </Grid>
                </Hidden>

                  <Hidden xsDown>
                    <Grid item>
                      <img src={logo} alt="logo" className={classes.image} style={{ height: '60px', width: 'auto' }} />
                    </Grid>
                  </Hidden>
                  
                  <Grid>
                    <Typography
                      variant="h5"
                      color="primary"
                    >
                    StrategySelector
                    </Typography>
                  </Grid>
            
                    <Grid item>
                      <a href="https://optionenhandeln.de/" target="_blank" rel="noopener noreferrer" >
                        <img src={OH_LOGO} alt='https://optionenhandeln.de/' style={{ height: '60px', width: 'auto' }} />
                      </a>
                    </Grid>
        
            </Grid>
      </Paper>
    );
  }

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Logo)));
