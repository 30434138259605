import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../components/optionStrategySelector/Logo";
import Stock from "../components/optionStrategySelector/Stock";
import Settings from "../components/optionStrategySelector/Settings";
import { PRIMARY, PRIMARY_TRANSPARENT, OH_LOGO } from "../config/config";

const styles = theme => ({
  mainContainer: {
    padding: 16,
    backgroundColor: PRIMARY_TRANSPARENT,
    flexGrow: 1
  }
});

class OptionStrategySelector extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Logo />
          </Grid>
          
          <Grid item xs={12}>
            <Settings />
          </Grid>


        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(OptionStrategySelector)));
