//import talib from 'ta-lib'
//import TA from 'ta-math'

//import { sma } from 'moving-averages'

//import tfjs from '@tensorflow/tfjs-core'

import { WILL_GET_SYMBOLS, GET_SYMBOLS_SUCCESS, GET_SYMBOLS_FAILURE, FILTER_SYMBOLS, WILL_GET_COMPANY_PROFILE, GET_COMPANY_PROFILE_SUCCESS, GET_COMPANY_PROFILE_FAILURE, GET_HISTORICAL_EQUITY_PRICE_SUCCESS, WILL_GET_HISTORICAL_EQUITY_PRICE, GET_HISTORICAL_EQUITY_PRICE_FAILURE, WILL_GET_COMPANY_KEY_METRICS, GET_COMPANY_KEY_METRICS_SUCCESS, GET_COMPANY_KEY_METRICS_FAILURE, WILL_GET_INCOME_STATEMENT_ANNUAL, WILL_GET_BALANCE_SHEET_QUATERLY, WILL_GET_INCOME_STATEMENT_QUATERLY, WILL_GET_COMPANY_RATING, GET_COMPANY_RATING_SUCCESS, GET_COMPANY_RATING_FAILURE } from "../actions/iexActions";



const sma = require('technicalindicatorslight').sma


const BearishEngulfingPattern = require('technicalindicatorslight').bearishengulfingpattern
const BearishHarami = require('technicalindicatorslight').bearishharami
const BearishHaramiCross = require('technicalindicatorslight').bearishmarubozu
const EveningDojiStar = require('technicalindicatorslight').eveningdojistar
const EveningStar = require('technicalindicatorslight').eveningstar
const BearishMarubozu = require('technicalindicatorslight').bearishmarubozu
const ThreeBlackCrows = require('technicalindicatorslight').threeblackcrows
const BearishHammerStick = require('technicalindicatorslight').bearishharami
const BearishInvertedHammerStick = require('technicalindicatorslight').bearishinvertedhammerstick
const HangingMan = require('technicalindicatorslight').hangingman
const HangingManUnconfirmed = require('technicalindicatorslight').hangingmanunconfirmed
const ShootingStar = require('technicalindicatorslight').shootingstar
const ShootingStarUnconfirmed = require('technicalindicatorslight').shootingstarunconfirmed
const TweezerTop = require('technicalindicatorslight').tweezertop

const BullishEngulfingPattern = require('technicalindicatorslight').bullishengulfingpattern
const DownsideTasukiGap = require('technicalindicatorslight').downsidetasukigap
const BullishHarami = require('technicalindicatorslight').bullishharami
const BullishHaramiCross = require('technicalindicatorslight').bullishharamicross
const MorningDojiStar = require('technicalindicatorslight').morningdojistar
const MorningStar = require('technicalindicatorslight').morningstar
const BullishMarubozu = require('technicalindicatorslight').bullishmarubozu
const PiercingLine = require('technicalindicatorslight').piercingline
const ThreeWhiteSoldiers = require('technicalindicatorslight').threewhitesoldiers
const BullishHammerStick = require('technicalindicatorslight').bullishhammerstick
const BullishInvertedHammerStick = require('technicalindicatorslight').bullishinvertedhammerstick
const HammerPattern = require('technicalindicatorslight').hammerpattern
const HammerPatternUnconfirmed = require('technicalindicatorslight').hammerpatternunconfirmed
const TweezerBottom = require('technicalindicatorslight').tweezerbottom

let bearishPatterns = [
    {name:'Bearish Engulfing Pattern', days: 2, func: BearishEngulfingPattern},
    {name:'Bearish Harami', days: 2, func: BearishHarami},
    {name:'Bearish Harami Cross', days: 2, func: BearishHaramiCross},
    {name:'Evening Doji Star', days: 3, func: EveningDojiStar},
    {name:'Evening Start', days: 3, func: EveningStar},
    {name:'Bearish Marubozu', days: 1, func: BearishMarubozu},
    {name:'Three Black Crows', days: 3, func: ThreeBlackCrows},
    {name:'Bearish Hammer Stick', days: 1, func: BearishHammerStick},
    {name:'Bearish Inverted Hammer Stick', days: 1, func: BearishInvertedHammerStick},
    {name:'Hanging Man', days: 5, func: HangingMan},
    {name:'Hanging Man Unconfirmed', days: 5, func: HangingManUnconfirmed},
    {name:'Shooting Star', days: 5, func: ShootingStar},
    {name:'Shooting Star Unconfirmed', days: 5, func: ShootingStarUnconfirmed},
    {name:'Tweezer Top', days: 5, func: TweezerTop}
];


let bullishPatterns = [
    { name: 'Bullish Engulfing Pattern', days: 2, func: BullishEngulfingPattern },
    { name: 'Downside Tasuki Gap', days: 3, func: DownsideTasukiGap },
    { name: 'Bullish Harami', days: 2, func: BullishHarami },
    { name: 'Bullish Harami Cross', days: 2, func: BullishHaramiCross },
    { name: 'Morning Doji Star', days: 3, func: MorningDojiStar },
    { name: 'Morning Star', days: 3, func: MorningStar },
    { name: 'Bullish Marubozu', days: 1, func: BullishMarubozu },
    { name: 'Piercing Line', days: 2, func: PiercingLine },
    { name: 'Three White Soldiers', days: 3, func: ThreeWhiteSoldiers },
    { name: 'Bullish Hammer Stick', days: 1, func: BullishHammerStick },
    { name: 'Bullish Inverted Hammer Stick', days: 1, func: BullishInvertedHammerStick },
    { name: 'Hammer Pattern', days: 5, func: HammerPattern },
    { name: 'Hammer Pattern Unconfirmed', days: 5, func: HammerPatternUnconfirmed },
    { name: 'Tweezer Bottom', days: 5, func: TweezerBottom }
];


const initState = {
    symbols: [],
    filteredSymbols: [],
    profiles: {},
    historicalEquityPrice: [],
    companyKeyMetrics: [],
    incomeStatement: [],
    balanceSheet: [],
    cashFlowStatement: [],
    companyRating: {},
    fetchingSymbols: false,
    fetchingProfile: false,
    fetchingHistoricalPrice: false,
    fetchingCompanyKeyMetrics: false,
    fetchingIncomeStatement: false,
    fetchingBalanceSheet: false,
    fetchingCashFlowStatement: false,
    fetchingCompanyRating: false,
    trend: 0,
    momentum: 0,
    candle: 50,
    isBearish: [],
    isBullish: [],
}

const iexReducer = (state=initState, action) => {
    switch (action.type) {
        case WILL_GET_SYMBOLS:
            return {
                ...state,
                fetchingSymbols: true
            }
        case GET_SYMBOLS_SUCCESS:
            return {
                ...state,
                symbols: action.data,
                fetchingSymbols: false,
            }
        case GET_SYMBOLS_FAILURE:
            return {
                ...state,
                fetchingSymbols: false,
            }

        case WILL_GET_COMPANY_PROFILE:
            return {
                ...state,
                fetchingProfile: true,
            }
        
        case GET_COMPANY_PROFILE_SUCCESS:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    [action.data.symbol]: action.data.profile
                },
                fetchingProfile: false
            }

        case GET_COMPANY_PROFILE_FAILURE:
            return {
                ...state,
                fetchingProfile: false
            }

        case WILL_GET_HISTORICAL_EQUITY_PRICE:
            return {
                ...state,
                fetchingHistoricalPrice: false
            }

        case GET_HISTORICAL_EQUITY_PRICE_SUCCESS:
            // get all closes for calculation of technical indicators
            const closes = action.data.historical.map(d => d.close)

            const sma10 = sma({period: 10, values: closes})
            const sma20 = sma({period: 20, values: closes})
            const sma50 = sma({period: 50, values: closes})
            const sma100 = sma({period: 100, values: closes})
            const sma200 = sma({period: 200, values: closes})

            const length = closes.length
            const trend = ((closes[length-1] > sma10[sma10.length-1]) + 
                           (closes[length-1] > sma20[sma20.length-1]) + 
                           (closes[length-1] > sma50[sma50.length-1]) + 
                           (closes[length-1] > sma100[sma100.length-1]) + 
                           (closes[length-1] > sma200[sma200.length-1])) * 20

            
            const chg10 = closes[length-1] - closes[length-1-10]
            const chg20 = closes[length-1] - closes[length-1-20]
            const chg50 = closes[length-1] - closes[length-1-50]
            const chg100 = closes[length-1] - closes[length-1-100]
            const chg200 = closes[length-1] - closes[length-1-200]

            const momentum = ((chg10 > 0) +
                              (chg20 > 0) +
                              (chg50 > 0) +
                              (chg100 > 0) +
                              (chg200 > 0)) * 20
            
            const isBullish = []
            const isBearish = []

            bearishPatterns.forEach(p => {
                const candleData = action.data.historical.slice(length-p.days-1, length-1)
                const candleInput = {open: [], high: [], close: [], low: []}
                candleData.forEach(cd => {
                    candleInput.open.push(cd.open)
                    candleInput.high.push(cd.high)
                    candleInput.close.push(cd.close)
                    candleInput.low.push(cd.low)
                })

                if(p.func(candleInput)) {
                    isBearish.push(p)
                }
            })

            bullishPatterns.forEach(p => {
                const candleData = action.data.historical.slice(length-p.days-1, length-1)
                const candleInput = {open: [], high: [], close: [], low: []}
                candleData.forEach(cd => {
                    candleInput.open.push(cd.open)
                    candleInput.high.push(cd.high)
                    candleInput.close.push(cd.close)
                    candleInput.low.push(cd.low)
                })

                if(p.func(candleInput)) {
                    isBullish.push(p)
                }
            })

            var candle = 50
            if (isBullish.length > isBearish.length) {
                candle = 100
            } else if (isBullish.length < isBearish.length) {
                candle = 0
            }
            

            return {
                ...state,
                historicalEquityPrice: action.data.historical,
                fetchingHistoricalPrice: false,
                trend: trend,
                momentum: momentum,
                candle: candle,
                isBearish: isBearish,
                isBullish: isBullish
            }

        case GET_HISTORICAL_EQUITY_PRICE_FAILURE:
            return {
                ...state,
                fetchingHistoricalPrice: false
            }

        case FILTER_SYMBOLS:
            const symbols = []
            var len = 0
            var index = 0
            var lenSymbols = state.symbols.length
            const searchTerm = action.data.toLowerCase()

            while (index < lenSymbols) {
                const symbol = state.symbols[index]
                if(len < 4) {
                    if (symbol.symbol.toLowerCase().includes(searchTerm) || 
                        symbol.name.toLowerCase().includes(searchTerm)) {
                        symbols.push(symbol)
                        len += 1
                    }
                } else {
                    if (symbol.symbol.toLowerCase() === searchTerm) {
                        symbols.unshift(symbol)
                        len += 1
                        break
                    }
                }

                index += 1
            }


            return {
                ...state,
                filteredSymbols: symbols
            }

        case WILL_GET_COMPANY_KEY_METRICS:
            return {
                ...state,
                fetchingCompanyKeyMetrics: true
            }

        case GET_COMPANY_KEY_METRICS_SUCCESS:
            const metrics = action.data.metrics

            return {
                ...state,
                fetchingCompanyKeyMetrics: false,
                companyKeyMetrics: metrics
            }

        case GET_COMPANY_KEY_METRICS_FAILURE:
            return {
                ...state,
                fetchingCompanyKeyMetrics: false
            }
        
        case WILL_GET_COMPANY_RATING:
            return {
                ...state,
                fetchingCompanyRating: true
            }

        case GET_COMPANY_RATING_SUCCESS:
            return {
                ...state,
                fetchingCompanyRating: false,
                companyRating: action.data
            }

        case GET_COMPANY_RATING_FAILURE:
            return {
                ...state,
                fetchingCompanyRating: false,
            }
            
        default:
            return state
    }
}

export default iexReducer