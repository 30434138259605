import { WILL_GET_INCOME_STATEMENT_ANNUAL, WILL_GET_INCOME_STATEMENT_QUATERLY, GET_INCOME_STATEMENT_ANNUAL_SUCCESS, GET_INCOME_STATEMENT_ANNUAL_FAILURE, GET_INCOME_STATEMENT_QUATERLY_SUCCESS, GET_INCOME_STATEMENT_QUATERLY_FAILURE } from "../actions/incomeStatementActions"


const initState = {
    incomeStatementAnnual: [],
    incomeStatementQuaterly: [],
    fetching: false
}

const incomeStatementReducer = (state=initState, action) => {
    switch (action.type) {
        case WILL_GET_INCOME_STATEMENT_QUATERLY:
            return {
                ...state,
                fetching: true
            }

        case GET_INCOME_STATEMENT_QUATERLY_SUCCESS:
            return {
                ...state,
                fetching: false,
                incomeStatementQuaterly: action.data
            }
        
        case GET_INCOME_STATEMENT_QUATERLY_FAILURE:
            return {
                ...state,
                fetching: false
            }


        case WILL_GET_INCOME_STATEMENT_ANNUAL:
            return {
                ...state,
                fetching: true
            }

        case GET_INCOME_STATEMENT_ANNUAL_SUCCESS:
            return {
                ...state,
                fetching: false,
                incomeStatementAnnual: action.data
            }
        
        case GET_INCOME_STATEMENT_ANNUAL_FAILURE:
            return {
                ...state,
                fetching: false
            }

        default:
            return state
    }
}

export default incomeStatementReducer