import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
//import 'firebase/storage'

var config = {
    apiKey: "AIzaSyBvI5enWnqJkwXvEir0c6ux3KmJqmtTAvU",
    authDomain: "moving-indicators.firebaseapp.com",
    databaseURL: "https://moving-indicators.firebaseio.com",
    projectId: "moving-indicators",
    storageBucket: "moving-indicators.appspot.com",
    messagingSenderId: "72571243589",
    appId: "1:72571243589:web:bc7c2da289e2f13b"
}

firebase.initializeApp(config)
firebase.firestore()
firebase.functions()
//firebase.storage()

export default firebase