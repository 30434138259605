import ReactGA from 'react-ga'
import axios from 'axios'

export const WILL_GET_DATA = 'WILL_GET_DATA'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE'
export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_LIST = 'UPDATE_LIST'

ReactGA.initialize('UA-140771558-1')


export function recordAppAction(data) {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()

        var record = firebase.functions().httpsCallable('recordAppAction')
        record(data)
    }
}

export const updateData = (name,valueOfAttribute) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_DATA,
            data: valueOfAttribute,
            name: name
        })
    }
}

export const updateList = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LIST,
            data: data,
        })
    }
}

export function calculate(symbol) {
    return (dispatch) => {
        axios({
            method: 'POST',
            url: 'http://us-central1-moving-indicators.cloudfunctions.net/strategyMaster', //API_URL + 'calculate',
            data: {symbol: symbol},
        }).then(response => {
            return dispatch({
                type: GET_DATA_SUCCESS,
                data: response.data
            })
            // console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }
}