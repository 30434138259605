import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';
import { makeStyles, fade } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import QuickSearchResults from '../search/QuickSearchResults';
import { InputBase } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import AppDetailPage from '../../views/AppDetailPage';
import { connect } from 'react-redux'
import { getSymbols } from '../../store/actions/iexActions';
import { getAllApps } from '../../store/actions/appActions';


const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    borderRadius: 18
  },
  search: {
    position: 'relative',
    borderRadius: 18,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    maxWidth: '300px',
  },
  searchIcon: {
    paddingLeft: 16,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: grey[800]
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 20,
    paddingLeft: 48,
    paddingTop: 8,
    paddingBottom: 8,
  },
}));


function SearchField(props) {
  const classes = useStyles();

  const [app, setApp] = useState('')
  const [appDetailPageOpen, setAppDetailPageOpen] = useState(false)

  const { symbols, fetchingSymbols, allApps, gettingApps, getSymbols, getAllApps, isMain } = props

  useEffect(() => {
    // Check if Data is Loaded and load if needed
    if (symbols.length === 0 && !fetchingSymbols) {
      getSymbols()
    }
    if (allApps.length === 0 && !gettingApps) {
      getAllApps()
    }
  })
  
  const handleCloseAppDetailPage = () => {
    setAppDetailPageOpen(false)
  }

  return (
    <div className={classes.search} style={{ maxWidth: isMain ? '100%' : '400px'}}>
      <Downshift id="downshift-simple">
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: 'Search Symbol (AAPL)',
          });

          return (
            <div className={classes.container}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                // value={searchTerm}
                // onChange={search}
                //onKeyPress={handleSearchEnter}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={inputProps}
                onFocus={onFocus}
                onBlur={onBlur}
              //onClick={handleSearchInputClicked}
              />

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper}>
                    <QuickSearchResults
                      searchTerm={inputValue}
                      setApp={setApp}
                      setAppDetailPageOpen={setAppDetailPageOpen}
                    />
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
      <AppDetailPage
        uid={props.auth.uid ? props.auth.uid : 0}
        selectedApp={app}
        open={appDetailPageOpen}
        handleClose={handleCloseAppDetailPage}
      />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    allApps: state.app.allApps,
    symbols: state.iex.symbols,
    gettingApps: state.app.gettingApps,
    fetchingSymbols: state.app.fetchingSymbols
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSymbols: () => dispatch(getSymbols()),
    getAllApps: () => dispatch(getAllApps()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchField)