import React, { useState } from 'react'
import { makeStyles, Grid, Typography, Button } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import {connect} from 'react-redux'
import SignInDialog from '../auth/SignInDialog'
import { enqueueSnackbar } from '../../store/actions/notificationActions'

const useStyles = makeStyles(theme => ({
    textWhite: {
        color: grey[600]
    }
}))

function GetRealtimeData(props) {

    const { auth, enqueueSnackbar } = props

    const classes = useStyles()
    const [singInDialogOpen, setSingInDialogOpen] = useState(false)

    const handleUpgrade = () => {
        if (!(auth && auth.uid)) {
            enqueueSnackbar({
                message: 'You have to Sign In before you can upgrade your Account!',
                options: {
                    variant: 'warning'
                }
            })
            setSingInDialogOpen(true)
        } else {
            enqueueSnackbar({
                message: 'We are working on providing you the best Real-Time Data out there! You will be notified via email as soon as we are ready!',
                options: {
                    variant: 'success'
                }
            })
        }
    }    

    return (
        <Grid container spacing={2} justify='center'>
            <Grid item />
            <Grid item xs={12}>
                <Typography variant='h5' align='center' className={classes.textWhite}>
                    Get Real-Time Data
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='body1' align='center' className={classes.textWhite}>
                    Upgrade Your Account
                        </Typography>
            </Grid>
            <Grid item>
                <Button variant='outlined' color='secondary' onClick={handleUpgrade}>Upgrade</Button>
            </Grid>
            <Grid item xs={12} />

            <SignInDialog
                open={singInDialogOpen}
                onClose={() => setSingInDialogOpen(false)}
            />
        </Grid>
        
    )
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = disptach => {
    return {
        enqueueSnackbar: (notification) => disptach(enqueueSnackbar(notification))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetRealtimeData)
