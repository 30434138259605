import { GET_DATA_SUCCESS, UPDATE_DATA, UPDATE_LIST } from "../actions/dataActions";

const initState = {
  autoFill: "",
  marketOutlook: "All",
  volatilityView: "All",
  timeErosion: "All",
  dividends: "All",
  interest: "All",
  profit: "All",
  loss: "All",
  optionStrategiesList: [
    "Long Call",
    "Short Call",
    "Long Put",
    "Short Put",
    "Covered Call",
    "Covered Put",
    "Protective Call",
    "Protective Put",
    "Bull Call Spread",
    "Bear Put Spread",
    "Long Butterfly",
    "Short Butterfly",
    "Long Condor",
    "Short Condor",
    "Long Straddle",
    "Short Straddle",
    "Long Strangle",
    "Short Strangle",
    "Ratio Call Spread",
    "Ratio Put Spread",
    "Collar",
    "Reversal",
    "Conversion",
    "Long Calendar Spread",
    "Short Calendar Spread"
  ],
  optionStrategy:""
};

const dataReducer = (state = initState, action) => {
  if (action.data != undefined) {
    // console.log(
    //   GET_DATA_SUCCESS &&
    //     action.data != undefined &&
    //     action.data.dividend == 0 &&
    //     action.data.interest == 0 &&
    //     action.data.volatility == 1
    // );
  }
  switch (action.type) {
    case GET_DATA_SUCCESS:
      if (
        action.data != undefined &&
        action.data.dividend == 0 &&
        action.data.interest == 0 &&
        action.data.volatility == 0
      )
        return {
          ...state,
          autoFill: action.data,
          volatilityView: "Decrease",
          dividends: "Decrease",
          interest: "Decrease",
          optionStrategiesList: [
            "Short Calendar Spread"
          ],
          optionStrategy:"Short Calendar Spread"
        };
      else {
        if (
          action.data != undefined &&
          action.data.dividend == 0 &&
          action.data.interest == 0 &&
          action.data.volatility == 1
        )
          return {
            ...state,
            autoFill: action.data,
            volatilityView: "Increase",
            dividends: "Decrease",
            interest: "Decrease",
            optionStrategiesList: [
              "Short Calendar Spread"
            ],
            optionStrategy:"Short Calendar Spread"
          };
        else {
          if (
            action.data != undefined &&
            action.data.dividend == 0 &&
            action.data.interest == 1 &&
            action.data.volatility == 0
          )
            return {
              ...state,
              autoFill: action.data,
              volatilityView: "Decrease",
              dividends: "Decrease",
              interest: "Increase",
              optionStrategiesList: [
                "Short Put",
                "Covered Put",
                "Ratio Put Spread",
                "Long Calendar Spread",
                "Short Calendar Spread"
              ],
              optionStrategy:"Short Put"
            };
          else {
            if (
              action.data != undefined &&
              action.data.dividend == 0 &&
              action.data.interest == 1 &&
              action.data.volatility == 1
            )
              return {
                ...state,
                autoFill: action.data,
                volatilityView: "Increase",
                dividends: "Decrease",
                interest: "Increase",
                optionStrategiesList: [
                  "Long Call",
                  "Protective Call",
                  "Short Calendar Spread"
                ],
                optionStrategy:"Long Call"
              };
            else {
              if (
                action.data != undefined &&
                action.data.dividend == 1 &&
                action.data.interest == 0 &&
                action.data.volatility == 0
              )
                return {
                  ...state,
                  autoFill: action.data,
                  volatilityView: "Decrease",
                  dividends: "Increase",
                  interest: "Decrease",
                  optionStrategiesList: [
                    "Short Call",
                    "Covered Call",
                    "Ratio Call Spread",
                    "Short Calendar Spread"
                  ],
                  optionStrategy: "Short Call",
                };
              else {
                if (
                  action.data != undefined &&
                  action.data.dividend == 1 &&
                  action.data.interest == 0 &&
                  action.data.volatility == 1
                )
                  return {
                    ...state,
                    autoFill: action.data,
                    volatilityView: "Increase",
                    dividends: "Increase",
                    interest: "Decrease",
                    optionStrategiesList: [
                      "Long Put",
                      "Protective Put",
                      "Short Calendar Spread"
                    ],
                    optionStrategy:"Long Put",
                  };
                else {
                  if (
                    action.data != undefined &&
                    action.data.dividend == 1 &&
                    action.data.interest == 1 &&
                    action.data.volatility == 0
                  )
                    return {
                      ...state,
                      autoFill: action.data,
                      volatilityView: "Decrease",
                      dividends: "Increase",
                      interest: "Increase",
                      optionStrategiesList: [
                        "Short Calendar Spread"
                      ],
                      optionStrategy:"Short Calendar Spread"
                    };
                  else {
                    if (
                      action.data != undefined &&
                      action.data.dividend == 1 &&
                      action.data.interest == 1 &&
                      action.data.volatility == 1
                    )
                      return {
                        ...state,
                        autoFill: action.data,
                        volatilityView: "Increase",
                        dividends: "Increase",
                        interest: "Increase",
                        optionStrategiesList: [
                          "Short Calendar Spread"
                        ],
                        optionStrategy:"Short Calendar Spread"
                      };
                  }
                }
              }
            }
          }
        }
      }
      case UPDATE_DATA:
        return{
          ...state,
          [action.name]:action.data
        }
        case UPDATE_LIST:
            return{
              ...state,
              optionStrategiesList:action.data
            }
    default:
      return state;
  }
};

export default dataReducer;
