import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const styles = theme => ({
  mainContainer: {
    padding: 32, 
    height: '80vh'
  },
  widgetContainer: {
    height: '100%',
    width: '100%'
  },
  widgetContainerSmall: {
    height: "100%",
    width: "100%",
    overflowX: "auto"
  }
});
class FinancialWidget extends Component {
  state = {};
  componentDidMount () {
    const script = document.createElement("script");

    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-financials.js" ;

    script.type= 'text/javascript';

    script.async = true;

    document.body.appendChild(script);
    document.body.removeChild(script);
}

componentDidUpdate(){
    const script = document.createElement("script");

    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-financials.js" ;

    script.type= 'text/javascript';

    script.async = true;

    document.body.appendChild(script);
    document.body.removeChild(script);
}

  render() {
    const { classes,symbol } = this.props;
    return (
      <div className={classes.mainContainer}>
          <Hidden smDown>
            
            <div  dangerouslySetInnerHTML={{__html: '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/ '+symbol+'/" rel="noopener" target="_blank"><span class="blue-text">'+symbol+' Fundamental Data</span></a> by TradingView</div><script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-financials.js" async>{"symbol":"'+symbol+'","colorTheme": "light","isTransparent": false,"largeChartUrl": "","displayMode": "compact","width": "405","height": "500","locale": "en"}</script></div>'}} className={classes.widgetContainer} />
          </Hidden>
          <Hidden mdUp>
          <div  dangerouslySetInnerHTML={{__html: '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/ '+symbol+'/" rel="noopener" target="_blank"><span class="blue-text">'+symbol+' Fundamental Data</span></a> by TradingView</div><script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-financials.js" async>{"symbol":"'+symbol+'","colorTheme": "light","isTransparent": false,"largeChartUrl": "","displayMode": "compact","width": "100%","height": "90%","locale": "en"}</script></div>'}} className={classes.widgetContainer} />
          </Hidden>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(FinancialWidget)));
