import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from "../actions/notificationActions";


const initState = {
    notifications: []
}

const notificationReducer = (state=initState, action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            console.log(ENQUEUE_SNACKBAR)
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification
                    }
                ]
            }

        case REMOVE_SNACKBAR:
            console.log(REMOVE_SNACKBAR)
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key
                )
            }

        default:
            return state
    }
}

export default notificationReducer